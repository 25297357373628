import { useState, useEffect } from 'react';
import { Text, Badge, Icon, Button } from '@shopify/polaris';
import { CircleTickMajor, CircleCancelMajor } from '@shopify/polaris-icons';

export const NotificationMessage = ({ 
    position = 'topRight',
    data
}) => {
    const [isVisible, setIsVisible] = useState(true);
    const [isHovered, setIsHovered] = useState(false);

    const handleClose = () => {
        // Add exit animation class
        const element = document.getElementById(`notification-${data.id}`);
        if (element) {
            element.style.transform = position.includes('Right') 
                ? 'translateX(120%)' 
                : 'translateX(-120%)';
            element.style.opacity = '0';
        }

        // Wait for animation to complete before hiding
        setTimeout(() => {
            setIsVisible(false);
              // markNotificationAsRead(data);
      
        }, 300);
    };

    // Auto-dismiss after 10 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            handleClose();
        }, 10000);

        return () => clearTimeout(timer);
    }, []);

    if (!isVisible) return null;

    return (
        <div 
            id={`notification-${data.id}`}
            style={{
                position: 'fixed',
                [position.includes('top') ? 'top' : 'bottom']: '20px',
                [position.includes('Right') ? 'right' : 'left']: '20px',
                width: '360px',
                zIndex: 1000,
                transform: 'translateX(0)',
                opacity: 1,
                transition: 'all 0.3s ease-in-out',
            }}
        >
            <div 
                style={{
                    backgroundColor: 'white',
                    borderRadius: '12px',
                    boxShadow: isHovered 
                        ? '0 8px 16px rgba(0, 0, 0, 0.12)' 
                        : '0 2px 8px rgba(0, 0, 0, 0.08)',
                    transition: 'all 0.3s ease',
                    overflow: 'hidden',
                    border: '1px solid #e1e3e5',
                    animation: `${position.includes('Right') ? 'slideInRight' : 'slideInLeft'} 0.3s ease-out`
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <style>
                    {`
                        @keyframes slideInRight {
                            from { transform: translateX(120%); opacity: 0; }
                            to { transform: translateX(0); opacity: 1; }
                        }
                        @keyframes slideInLeft {
                            from { transform: translateX(-120%); opacity: 0; }
                            to { transform: translateX(0); opacity: 1; }
                        }
                    `}
                </style>

                {/* Image Section */}
                {data.image_url && (
                    <div style={{
                        width: '100%',
                        height: '160px',
                        position: 'relative',
                        overflow: 'hidden'
                    }}>
                        <img
                            src={data.image_url}
                            alt="Event"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                transition: 'transform 0.3s ease',
                                transform: isHovered ? 'scale(1.05)' : 'scale(1)',
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                const newWindow = window.open();
                                newWindow.document.write(`
                                    <html>
                                        <head>
                                            <title>Event Image</title>
                                            <style>
                                                body {
                                                    margin: 0;
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    min-height: 100vh;
                                                    background: #000;
                                                }
                                                img {
                                                    max-width: 100%;
                                                    max-height: 100vh;
                                                    object-fit: contain;
                                                }
                                            </style>
                                        </head>
                                        <body>
                                            <img src="${data.image_url}" alt="Full size"/>
                                        </body>
                                    </html>
                                `);
                            }}
                        />
                    </div>
                )}

                {/* Content Section */}
                <div style={{
                    padding: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px'
                }}>
                    {/* Header */}
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px'
                        }}>
                            <Icon
                                source={data.read ? CircleTickMajor : CircleCancelMajor}
                                tone={data.read ? "success" : "critical"}
                            />
                            <Badge tone={data.read ? "success" : "attention"}>
                                {data.read ? "Read" : "New"}
                            </Badge>
                        </div>
                        <Text variant="bodySm" tone="subdued">
                            {new Date(data.timestamp).toLocaleString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true
                            })}
                        </Text>
                    </div>

                    {/* Message Content */}
                    <div style={{
                        backgroundColor: '#f4f6f8',
                        padding: '16px',
                        borderRadius: '8px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px'
                    }}>
                        {/* Camera Name */}
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px'
                        }}>
                            <Text variant="bodyMd" fontWeight="bold" tone="subdued">
                                Camera:
                            </Text>
                            <Text variant="bodyMd" fontWeight="semibold">
                                {data.camera_name}
                            </Text>
                        </div>

                        {/* Scenario Name */}
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px'
                        }}>
                            <Text variant="bodyMd" fontWeight="bold" tone="subdued">
                                Scenario:
                            </Text>
                            <Text variant="bodyMd" fontWeight="semibold">
                                {data.scenario_name}
                            </Text>
                        </div>

                        {/* Events */}
                        <div style={{
                            marginTop: '4px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '4px'
                        }}>
                            <Text variant="bodyMd" fontWeight="bold" tone="subdued">
                                Events:
                            </Text>
                            <div style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '6px',
                                marginTop: '4px'
                            }}>
                                {data.event_names?.map((event, index) => (
                                    <Badge
                                        key={`event-${index}`}
                                        tone={event.toLowerCase().includes('no') ? 'critical' : 'success'}
                                        size="small"
                                    >
                                        {event}
                                    </Badge>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Close Button */}
                    <Button 
                        plain 
                        onClick={handleClose}
                        tone="critical"
                    >
                        Dismiss
                    </Button>
                </div>
            </div>
        </div>
    );
};