/* eslint-disable */
import { useMutation, useQuery } from '@tanstack/react-query';
import { ArrowLeft, Info, Turtle } from 'lucide-react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getCameraById } from '../../api/cameras.api';
import { Button, RangeSlider, Select, Spinner, Tooltip } from '@shopify/polaris';
import { notification } from 'antd';
import {
  applyScenarioForCamera,
  getAppliedScenarioByAppliedScenarioId,
  getScenarioByName
} from '../../api/scenarios.api';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetScenarioPerCamFormData,
  setScenarioPerCamFormData
} from '../../store/slices/scenarioSlice';
import Canvas from '../../components/polygon-tool/Canvas';

function isImageUrlValid(url) {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = url;
  });
}

const Checkmark = ({ isChecked, onClick }) => {
  return (
    <div
      className={`flex cursor-pointer items-center justify-center w-6 h-6 rounded-full ${isChecked ? 'bg-green-500' : 'border border-gray-300'}`}
      onClick={onClick}
    >
      {isChecked && (
        <svg
          className="w-4 h-4 text-white"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
        </svg>
      )}
    </div>
  );
};

const ApplyScenarioForm = () => {
  const [api, contextHolder] = notification.useNotification({ maxCount: 1 });
  const navigateTo = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedEventIdx, setSelectedEventIdx] = useState();
  const [selectedEventName, setSelectedEventName] = useState();

  const {
    data: appliedScenarioInfo,
    isLoading: isAppliedScenarioLoading,
    isError: didAppliedScenarioNotLoad
  } = useQuery({
    queryKey: ['appliedScenarioInfo', searchParams.get('appliedScenarioId')],
    queryFn: () => getAppliedScenarioByAppliedScenarioId(searchParams.get('appliedScenarioId')),
    enabled: searchParams.get('appliedScenarioId') !== null
  });

  const {
    mutate: applyScenarioApi,
    isPending: isApplyingScenario,
    error
  } = useMutation({
    mutationFn: async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      return applyScenarioForCamera({
        cameraName: camera.name,
        scenario: (scenarioInfo && scenarioInfo.name) ?? searchParams.get('scenario'),
        cameraId: searchParams.get('cameraId'),
        appliedScenarioId: searchParams.get('appliedScenarioId'),
        payload: formData,
        scenarioTitle: scenarioInfo.title,
        eventBasedZones: scenarioInfo.event_based_zones
      });
    },
    onSuccess: () => {
      api.success({
        message: 'Scenario Applied',
        description: 'The scenario has been successfully applied to the camera.'
      });
      navigateTo(`/cameras/${searchParams.get('cameraId')}`);
    },
    onError: (e) => {
      api.error({
        message: 'Failed to Apply Scenario',
        description: 'There was an error while applying the scenario. Please try again.'
      });
    }
  });

  const { scenarioPerCamFormData: formData } = useSelector((state) => state.scenario);
  const dispatch = useDispatch();

  const [selectedEventConfig, setSelectedEventConfig] = useState({});

  const [polygons, setPolygons] = useState([[]]);
  const [isPolygonCompleteArr, setIsPolygonCompleteArr] = useState([false]);
  const [currentPolygonIdx, setCurrentPolygonIdx] = useState(0);

  const [makeViewOnly, setMakeViewOnly] = useState(true);
  const [nextStep, setNextStep] = useState(false);

  const [disableOverlay, setDisableOverlay] = useState(false);

  const [disablePolygonSubmission, setDisablePolygonSubmission] = useState(true);
  const reset = () => {
    setPolygons([[]]);
    setIsPolygonCompleteArr([false]);
    setCurrentPolygonIdx(0);
  };

  const addNewZone = () => {
    if (polygons.length === 1 && polygons[0].length === 0) return;
    setPolygons([...polygons, []]);
    setIsPolygonCompleteArr([...isPolygonCompleteArr, false]);
    setCurrentPolygonIdx(polygons.length);
  };

  useEffect(() => {
    if (searchParams.get('appliedScenarioId') == null) {
      dispatch(resetScenarioPerCamFormData());
    }
  }, [searchParams]);

  const {
    data: camera,
    isLoading,
    isError
  } = useQuery({
    queryKey: ['cameraInfo', searchParams.get('cameraId')],
    queryFn: () => getCameraById(searchParams.get('cameraId'))
  });
  useEffect(() => {
    const REDIRECT_DELAY = 5000; // 5 seconds

    async function checkImageAndRedirect() {
      if (camera) {
        const isValid = await isImageUrlValid(camera.thumbnail_url);
        if (!isValid) {
          api.info({
            message: `Camera has no thumbnail. Redirecting in ${REDIRECT_DELAY / 1000} seconds`
          });
          setDisableOverlay(true);
          const timer = setTimeout(() => navigateTo('/cameras'), REDIRECT_DELAY);
          return () => clearTimeout(timer);
        }
      }
    }

    checkImageAndRedirect();

    // Clean-up function
    return () => {
      setDisableOverlay(false);
    };
  }, [camera, navigateTo, api, isImageUrlValid]);
  const {
    data: scenarioInfo,
    isLoading: isScenarioLoading,
    isError: didScenarioNotLoad
  } = useQuery({
    queryKey: ['scenarioInfo', searchParams.get('scenario')],
    queryFn: () => getScenarioByName(searchParams.get('scenario'))
  });

  // for editing the scenario
  useEffect(() => {
    if (scenarioInfo && appliedScenarioInfo && appliedScenarioInfo.data) {
      console.log(scenarioInfo);
      // for events object:
      let events;
      events = appliedScenarioInfo.data.zones.map((zone) => {
        const eventPayload = {
          name: zone.event_label,
          zones: zone.zones,
          label: zone.event_name
        };
        if (zone.metadata) {
          eventPayload.custom_config = zone.metadata;
        } else {
          eventPayload.custom_config = {};
        }
        return eventPayload;
      });

      if (!scenarioInfo.event_based_zones && scenarioInfo.zone_required) {
        // find the entry with name === scenarioInfo.name
        // get the zone data and assign it to all event's zone objects
        const requiredEvent = events.find((ev) => {
          if (ev.name === scenarioInfo.name) {
            return true;
          }
          return false;
        });

        if (requiredEvent) {
          events = events.map((ev) => {
            return { ...ev, zones: requiredEvent.zones };
          });

          setPolygons(requiredEvent.zones);
          setIsPolygonCompleteArr(Array.from({ length: requiredEvent.zones.length }, () => true));
          setCurrentPolygonIdx(requiredEvent.zones.length - 1);
        }
      }

      const modelConfidence = appliedScenarioInfo.data.appliedScenario.selected_confidence;
      const metadata = JSON.parse(appliedScenarioInfo.data.appliedScenario.scenario_metadata);
      const detectionDuration = metadata.duration_of_detection;
      const hysteresisTime = metadata.hysteresisTime;

      const dispatchPayload = {
        events,
        modelConfidence,
        detectionDuration,
        hysteresisTime,
        mlVersion: '0.0.1'
      };

      dispatch(setScenarioPerCamFormData(dispatchPayload));
    }
  }, [appliedScenarioInfo, scenarioInfo]);

  if (searchParams.get('appliedScenarioId') !== null && isAppliedScenarioLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Spinner size="large" />
      </div>
    );
  }

  if (isLoading || isScenarioLoading || isApplyingScenario) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Spinner size="large" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex justify-center items-center min-h-screen text-center">
        <p>Camera not found. Please check the ID or your network connection.</p>
      </div>
    );
  }

  return (
    <>
      {disableOverlay && <div className="fixed inset-0 bg-black bg-opacity-50 z-50" />}
      <div className={`max-h-screen relative ${disableOverlay ? 'pointer-events-none' : ''}`}>
        {' '}
        {contextHolder}
        <div className="bg-white h-[3rem] flex items-center text-[13px] p-2">
          <div className="rounded-md hover:opacity-50 hover:bg-[#D4D4D4]">
            <ArrowLeft size={24} className="cursor-pointer" onClick={() => navigateTo(-1)} />
          </div>
          <p className="font-semibold text-lg ml-4">{camera.name}</p>
        </div>
        {nextStep ? (
          <div className="py-4 flex justify-center items-center h-[80vh]">
            <div className="bg-white rounded-md w-[75%] px-10">
              <div className="my-6 flex sm:flex-col lg:flex-row justify-between lg:items-center">
                <div className="flex items-center">
                  <h6 className="semibold mb-2 mr-1">Model Version</h6>
                  <span className="mb-2">
                    <Tooltip title="Choose the model version. Newer versions may improve detection accuracy and speed.">
                      <Info size={14} />
                    </Tooltip>
                  </span>
                </div>
                <div className="w-2/4">
                  <Select
                    labelHidden
                    options={[
                      {
                        label: `Version 0.0.1`,
                        value: '0.0.1'
                      }
                    ]}
                    onChange={() => { }}
                  />
                </div>
              </div>
              <hr />
              <div className="my-6 flex sm:flex-col lg:flex-row justify-between lg:items-center">
                <div className="flex items-center">
                  <h6 className="semibold mb-2 mr-1">Model Confidence</h6>
                  <span className="mb-2">
                    <Tooltip title="Set confidence level. Higher levels reduce false positives but might miss some events.">
                      <Info size={14} />
                    </Tooltip>
                  </span>
                </div>
                <div className="w-2/4">
                  <RangeSlider
                    labelHidden
                    value={formData.modelConfidence}
                    output
                    onChange={(newValue) =>
                      dispatch(
                        setScenarioPerCamFormData({ ...formData, modelConfidence: newValue })
                      )
                    }
                    helpText={'Model Confidence: ' + formData.modelConfidence + '%'}
                  />
                </div>
              </div>
              <hr />
              <div className="my-6 flex sm:flex-col lg:flex-row justify-between lg:items-center">
                <div className="flex items-center">
                  <h6 className="semibold mb-2 mr-1">Duration of Detection</h6>
                  <span className="mb-2">
                    <Tooltip title="Minimum event duration (in seconds) to trigger a notification. Prevents alerts for brief, irrelevant events.">
                      <Info size={14} />
                    </Tooltip>
                  </span>
                </div>
                <div className="w-2/4">
                  <RangeSlider
                    labelHidden
                    value={formData.detectionDuration}
                    helpText={formData.detectionDuration + ' seconds'}
                    output
                    onChange={(newValue) =>
                      dispatch(
                        setScenarioPerCamFormData({
                          ...formData,
                          detectionDuration: newValue
                        })
                      )
                    }
                    min={1}
                    max={15}
                  />
                </div>
              </div>
              <hr />
              <div className="my-6 flex sm:flex-col lg:flex-row justify-between lg:items-center">
                <div className="flex items-center">
                  <h6 className="semibold mb-2 mr-1">Hysteresis Time</h6>
                  <span className="mb-2">
                    <Tooltip title="Cool-down period (in minutes) before reporting a new event of the same type to avoid repeated notifications.">
                      <Info size={14} />
                    </Tooltip>
                  </span>
                </div>
                <div className="w-2/4">
                  <RangeSlider
                    labelHidden
                    value={formData.hysteresisTime}
                    helpText={formData.hysteresisTime + ' minutes'}
                    output
                    onChange={(newValue) =>
                      dispatch(setScenarioPerCamFormData({ ...formData, hysteresisTime: newValue }))
                    }
                    min={0}
                    max={10}
                  />
                </div>
              </div>

              <div className="">
                <div className="">
                  <div className="flex flex-row-reverse gap-2 pb-2">
                    <Button
                      variant="primary"
                      onClick={() => {
                        applyScenarioApi();
                      }}
                    >
                      Apply Scenario
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setNextStep(false);
                      }}
                    >
                      {'< '}Back
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="py-4 px-4">
            <div className="flex flex-col md:flex-row md:justify-around gap-4">
              <div className="h-full w-full md:w-3/5 bg-white px-4 py-4 rounded-md">
                <div className="flex flex-row justify-between">
                  <label className="font-semibold text-lg">{camera.name}</label>
                  {scenarioInfo ? (
                    scenarioInfo.event_based_zones ? (
                      !makeViewOnly &&
                      selectedEventName &&
                      scenarioInfo.events[selectedEventName]['zone_required'] && (
                        <div className="flex gap-x-2">
                          <Button
                            onClick={() => {
                              setPolygons([[]]);
                              setIsPolygonCompleteArr([false]);
                              setCurrentPolygonIdx(0);
                              dispatch(
                                setScenarioPerCamFormData({
                                  ...formData,
                                  events: formData.events.filter((e) => e.zones.length === 0)
                                })
                              );
                            }}
                            variant="primary"
                            tone="critical"
                          >
                            Reset All Zones
                          </Button>
                          <Button variant="secondary" onClick={reset}>
                            Reset Current Zone
                          </Button>
                          {scenarioInfo.events[selectedEventName]['zone_required'] &&
                            scenarioInfo.events[selectedEventName]['multiple_zones'] && (
                              <Button variant="primary" onClick={addNewZone}>
                                Add New Zone
                                
                              </Button>
                            )}
                        </div>
                      )
                    ) : scenarioInfo.zone_required ? (
                      <div className="flex gap-x-2">
                        <Button
                          onClick={() => {
                            setPolygons([[]]);
                            setIsPolygonCompleteArr([false]);
                            setCurrentPolygonIdx(0);
                            dispatch(
                              setScenarioPerCamFormData({
                                ...formData,
                                events: formData.events.filter((e) => e.zones.length === 0)
                              })
                            );
                          }}
                          variant="primary"
                          tone="critical"
                        >
                          Reset All Zones
                        </Button>
                        <Button variant="secondary" onClick={reset}>
                          Reset Current Zone
                        </Button>
                        <Button variant="primary" onClick={addNewZone}>
                          Add New Zone
                        </Button>
                      </div>
                    ) : (
                      // leave empty
                      <></>
                    )
                  ) : (
                    // leave empty
                    <></>
                  )}
                </div>
                <hr className="mb-2 mt-1" />
                {scenarioInfo ? (
                  scenarioInfo.event_based_zones ? (
                    !makeViewOnly ? (
                      selectedEventName &&
                        scenarioInfo.events[selectedEventName]['zone_required'] ? (
                        <div className="canvas-parent">
                          <Canvas
                            camera={camera}
                            imageSrc={camera.thumbnail_url}
                            polygons={polygons}
                            setPolygons={setPolygons}
                            isPolygonCompleteArr={isPolygonCompleteArr}
                            setIsPolygonCompleteArr={setIsPolygonCompleteArr}
                            currentPolygonIdx={currentPolygonIdx}
                            setCurrentPolygonIdx={setCurrentPolygonIdx}
                            setDisableSubmit={setDisablePolygonSubmission}
                            forParent={true}
                          />
                          <div className="flex flex-row-reverse mt-2">
                            <Button
                              onClick={() => {
                                const updatedEvents = formData.events.filter(
                                  (e) => e.name !== selectedEventName
                                );
                                updatedEvents.push({
                                  label: scenarioInfo.events[selectedEventName].label,
                                  zones: [...polygons],
                                  custom_config: { ...selectedEventConfig },
                                  name: selectedEventName
                                });
                                dispatch(
                                  setScenarioPerCamFormData({
                                    ...formData,
                                    events: updatedEvents
                                  })
                                );
                                setPolygons([[]]);
                                setIsPolygonCompleteArr([false]);
                                setCurrentPolygonIdx(0);
                                setMakeViewOnly(true);
                              }}
                              disabled={disablePolygonSubmission}
                              variant="primary"
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <img src={camera.thumbnail_url} alt={`${camera.name} image`} />
                      )
                    ) : (
                      <div className="canvas-parent">
                        <Canvas
                          camera={camera}
                          imageSrc={camera.thumbnail_url}
                          readOnly={true}
                          polygons={formData.events.map((e) => e.zones).flat()}
                          setPolygons={setPolygons}
                          isPolygonCompleteArr={isPolygonCompleteArr}
                          setIsPolygonCompleteArr={setIsPolygonCompleteArr}
                          currentPolygonIdx={currentPolygonIdx}
                          setCurrentPolygonIdx={setCurrentPolygonIdx}
                          setDisableSubmit={setDisablePolygonSubmission}
                          forParent={true}
                        />
                      </div>
                    )
                  ) : scenarioInfo.zone_required ? (
                    <div className="canvas-parent">
                      <Canvas
                        camera={camera}
                        imageSrc={camera.thumbnail_url}
                        polygons={polygons}
                        setPolygons={setPolygons}
                        isPolygonCompleteArr={isPolygonCompleteArr}
                        setIsPolygonCompleteArr={setIsPolygonCompleteArr}
                        currentPolygonIdx={currentPolygonIdx}
                        setCurrentPolygonIdx={setCurrentPolygonIdx}
                        setDisableSubmit={setDisablePolygonSubmission}
                        forParent={true}
                      />
                    </div>
                  ) : (
                    // use img
                    <img src={camera.thumbnail_url} alt={`${camera.name} image`} />
                  )
                ) : (
                  // leave empty
                  <></>
                )}
              </div>
              <div className="w-full md:w-2/5">
                <div className="bg-white rounded-md shadow-xl mb-4 pb-4">
                  <div className="flex items-center justify-between p-4">
                    <h3 className="font-semibold text-[16px] pb-0">Event Configuration</h3>
                  </div>
                  <hr />
                  <div className="flex flex-col ">
                    {scenarioInfo &&
                      Object.keys(scenarioInfo.events)
                        .filter((e) => {
                          return scenarioInfo.events[e].completed === true;
                        })
                        .map((event, idx) => {
                          return (
                            <div
                              key={event}
                              className={`border-b cursor-pointer px-4 py-2 flex justify-between ${selectedEventIdx === idx ? 'bg-[#F7F4FF]' : ''} ${event !== selectedEventName && 'text-gray-300'} hover:text-gray-700`}
                            >
                              <span
                                onClick={() => {
                                  // to prevent user from clicking on another event without saving zone
                                  if (scenarioInfo.event_based_zones) {
                                    if (
                                      ((polygons.length === 1 && polygons[0].length !== 0) ||
                                        (isPolygonCompleteArr.length === 1 &&
                                          isPolygonCompleteArr[0] !== false) ||
                                        currentPolygonIdx !== 0) &&
                                      scenarioInfo.events[event].zone_required
                                    ) {
                                      return api.warning({
                                        message: 'Please save created zones',
                                        placement: 'topRight'
                                      });
                                    }
                                  }

                                  // selects zone for config purposes
                                  setSelectedEventIdx(idx);
                                  setSelectedEventName(event);

                                  // reset polygons only when event_based_zones is true
                                  if (scenarioInfo.event_based_zones) {
                                    // reset polygons to original data if available
                                    const requiredEventObject = formData.events.find((evObj) => {
                                      return evObj.name === event;
                                    });

                                    if (!requiredEventObject) {
                                      setPolygons([[]]);
                                      setIsPolygonCompleteArr([false]);
                                      setCurrentPolygonIdx(0);
                                    } else {
                                      setPolygons([...requiredEventObject.zones]);
                                      setIsPolygonCompleteArr([
                                        ...Array(requiredEventObject.zones.length).fill(true)
                                      ]);
                                      setCurrentPolygonIdx(requiredEventObject.zones.length - 1);
                                      setDisablePolygonSubmission(false);
                                      setMakeViewOnly(false);
                                    }
                                  }

                                  // if event has no custom config
                                  if (
                                    !('config' in scenarioInfo.events[event]) ||
                                    (scenarioInfo.events[event].config &&
                                      Object.keys(scenarioInfo.events[event].config).length === 0)
                                  ) {
                                    // if event does not exist in redux, prompt user to create zone and add event to events in redux
                                    if (
                                      formData.events.find((e) => e.name === event) === undefined
                                    ) {
                                      // do nothing if zone is required
                                      if (scenarioInfo.events[event]['zone_required']) {
                                        setMakeViewOnly(false);
                                        return;
                                      } else {
                                        setMakeViewOnly(true);
                                      }

                                      dispatch(
                                        setScenarioPerCamFormData({
                                          ...formData,
                                          events: [
                                            ...formData.events,
                                            {
                                              name: event,
                                              zones: [],
                                              custom_config: {},
                                              label: scenarioInfo.events[event].label
                                            }
                                          ]
                                        })
                                      );
                                    } else {
                                      // remove event from redux
                                      // TODO: if zone already exists, do not remove it but edit it
                                      dispatch(
                                        setScenarioPerCamFormData({
                                          ...formData,
                                          events: formData.events.filter((e) => e.name !== event)
                                        })
                                      );
                                    }
                                  } else {
                                    // if event has custom config
                                    let statePayload = JSON.parse(
                                      JSON.stringify(scenarioInfo.events[event].config)
                                    );

                                    for (let key in statePayload) {
                                      if (statePayload[key].datatype === 'slider') {
                                        statePayload[key] = {
                                          ...statePayload[key]
                                        };
                                        const eventInfo = formData.events.find(
                                          (ev) => ev.name === event
                                        );
                                        if (eventInfo) {
                                          statePayload[key].value =
                                            eventInfo.custom_config[key].value ??
                                            statePayload[key].default_value;
                                        } else {
                                          statePayload[key].value = statePayload[key].default_value;
                                        }
                                      }
                                    }

                                    // set event config in state variable
                                    setSelectedEventConfig(statePayload);

                                    // if event in redux
                                    if (
                                      formData.events.find((e) => e.name === event) === undefined
                                    ) {
                                      // do nothing if zone is required
                                      if (scenarioInfo.events[event]['zone_required']) {
                                        // api.info({
                                        //   message: 'Zone Required',
                                        //   description:
                                        //     'Please draw a zone for this event before proceeding.'
                                        // });
                                        setMakeViewOnly(false);
                                        return;
                                      } else {
                                        setMakeViewOnly(true);
                                      }
                                      dispatch(
                                        setScenarioPerCamFormData({
                                          ...formData,
                                          events: [
                                            ...formData.events,
                                            {
                                              name: event,
                                              zones: [],
                                              custom_config: {},
                                              label: scenarioInfo.events[event].label
                                            }
                                          ]
                                        })
                                      );
                                    } else {
                                      // if event not in redux
                                      // TODO: if zone already exists, do not remove it but edit it
                                      dispatch(
                                        setScenarioPerCamFormData({
                                          ...formData,
                                          events: formData.events.filter((e) => e.name !== event)
                                        })
                                      );
                                    }
                                  }
                                }}
                                className={`w-full`}
                              >
                                {event
                                  .replaceAll('_', ' ')
                                  .toLowerCase()
                                  .split(' ')
                                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                  .join(' ')}
                              </span>
                              <Checkmark
                                onClick={() => {
                                  // to prevent user from clicking on another event without saving zone
                                  if (scenarioInfo.event_based_zones) {
                                    if (
                                      (polygons.length === 1 && polygons[0].length !== 0) ||
                                      (isPolygonCompleteArr.length === 1 &&
                                        isPolygonCompleteArr[0] !== false) ||
                                      currentPolygonIdx !== 0
                                    ) {
                                      return api.warning({
                                        message: 'Please save created zones',
                                        placement: 'topRight'
                                      });
                                    }
                                  }

                                  // selects zone for config purposes
                                  setSelectedEventIdx(idx);
                                  setSelectedEventName(event);

                                  // reset polygons only when event_based_zones is true
                                  if (scenarioInfo.event_based_zones) {
                                    // reset polygons
                                    setPolygons([[]]);
                                    setIsPolygonCompleteArr([false]);
                                    setCurrentPolygonIdx(0);
                                  }

                                  // if event has no custom config
                                  if (
                                    !('config' in scenarioInfo.events[event]) ||
                                    (scenarioInfo.events[event].config &&
                                      Object.keys(scenarioInfo.events[event].config).length === 0)
                                  ) {
                                    // if event does not exist in redux, prompt user to create zone and add event to events in redux
                                    if (
                                      formData.events.find((e) => e.name === event) === undefined
                                    ) {
                                      // do nothing if zone is required
                                      if (scenarioInfo.events[event]['zone_required']) {
                                        setMakeViewOnly(false);
                                        return;
                                      } else {
                                        setMakeViewOnly(true);
                                      }

                                      dispatch(
                                        setScenarioPerCamFormData({
                                          ...formData,
                                          events: [
                                            ...formData.events,
                                            {
                                              name: event,
                                              zones: [],
                                              custom_config: {},
                                              label: scenarioInfo.events[event].label
                                            }
                                          ]
                                        })
                                      );
                                    } else {
                                      // remove event from redux
                                      // TODO: if zone already exists, do not remove it but edit it
                                      dispatch(
                                        setScenarioPerCamFormData({
                                          ...formData,
                                          events: formData.events.filter((e) => e.name !== event)
                                        })
                                      );
                                    }
                                  } else {
                                    // if event has custom config
                                    let statePayload = JSON.parse(
                                      JSON.stringify(scenarioInfo.events[event].config)
                                    );

                                    for (let key in statePayload) {
                                      if (statePayload[key].datatype === 'slider') {
                                        statePayload[key] = {
                                          ...statePayload[key],
                                          value: statePayload[key].default_value
                                        };
                                      }
                                    }

                                    // set event config in state variable
                                    setSelectedEventConfig(statePayload);

                                    // if event in redux
                                    if (
                                      formData.events.find((e) => e.name === event) === undefined
                                    ) {
                                      // do nothing if zone is required
                                      if (scenarioInfo.events[event]['zone_required']) {
                                        // api.info({
                                        //   message: 'Zone Required',
                                        //   description:
                                        //     'Please draw a zone for this event before proceeding.'
                                        // });
                                        setMakeViewOnly(false);
                                        return;
                                      } else {
                                        setMakeViewOnly(true);
                                      }
                                      dispatch(
                                        setScenarioPerCamFormData({
                                          ...formData,
                                          events: [
                                            ...formData.events,
                                            {
                                              name: event,
                                              zones: [],
                                              custom_config: {},
                                              label: scenarioInfo.events[event].label
                                            }
                                          ]
                                        })
                                      );
                                    } else {
                                      // if event not in redux
                                      // TODO: if zone already exists, do not remove it but edit it
                                      dispatch(
                                        setScenarioPerCamFormData({
                                          ...formData,
                                          events: formData.events.filter((e) => e.name !== event)
                                        })
                                      );
                                    }
                                  }
                                }}
                                isChecked={(() => {
                                  return (
                                    formData.events.find((e) => {
                                      return e.name === event;
                                    }) !== undefined
                                  );
                                })()}
                              />
                            </div>
                          );
                        })}
                  </div>
                </div>
                {selectedEventIdx !== undefined &&
                  'config' in scenarioInfo.events[selectedEventName] &&
                  Object.keys(scenarioInfo.events[selectedEventName].config).length > 0 && (
                    <div className="bg-white py-4 mt-4 px-4 rounded-md shadow-xl">
                      <h4 className="font-semibold text-[16px] pb-1">
                        {selectedEventName
                          .replaceAll('_', ' ')
                          .toLowerCase()
                          .split(' ')
                          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(' ')}{' '}
                        Settings
                      </h4>
                      <div className="border rounded-md px-2 py-2">
                        {Object.keys(scenarioInfo.events[selectedEventName].config).map(
                          (field, idx) => {
                            if (scenarioInfo.events[selectedEventName].config[field].datatype === 'slider') {
                              return (
                                <div>
                                  <label className="font-semibold mb-1">
                                    {field
                                      .replaceAll('_', ' ')
                                      .toLowerCase()
                                      .split(' ')
                                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                      .join(' ')}
                                  </label>
                                  <RangeSlider
                                    min={scenarioInfo.events[selectedEventName].config[field].min_value}
                                    max={scenarioInfo.events[selectedEventName].config[field].max_value}
                                    labelHidden
                                    value={selectedEventConfig[field] ? selectedEventConfig[field].value : scenarioInfo.events[selectedEventName].config[field].min_value}
                                    onChange={(value) => {
                                      setSelectedEventConfig({
                                        ...selectedEventConfig,
                                        [field]: {
                                          ...selectedEventConfig[field],
                                          value: value
                                        }
                                      });
                                    }}
                                    output
                                    helpText={`${selectedEventConfig[field] ? `${selectedEventConfig[field].value} ${selectedEventConfig[field].unit}` : scenarioInfo.events[selectedEventName].config[field].min_value}`}
                                  />
                                </div>
                              );
                            } else if (scenarioInfo.events[selectedEventName].config[field].datatype === 'radio') {
                              return (
                                <div className="mb-4">
                                  <label className="font-semibold mb-2 block">
                                    {field
                                      .replaceAll('_', ' ')
                                      .toLowerCase()
                                      .split(' ')
                                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                      .join(' ')}
                                  </label>
                                  <div className="flex flex-row gap-4">
                                    {scenarioInfo.events[selectedEventName].config[field].options.map((option) => (
                                      <div key={option.value} className="flex items-center">
                                        <input
                                          type="radio"
                                          id={`${field}-${option.value}`}
                                          name={field}
                                          value={option.value}
                                          checked={selectedEventConfig[field]?.value === option.value}
                                          onChange={(e) => {
                                            setSelectedEventConfig({
                                              ...selectedEventConfig,
                                              [field]: {
                                                ...selectedEventConfig[field],
                                                value: e.target.value
                                              }
                                            });
                                          }}
                                          className="h-4 w-4 text-primary-600 border-gray-300 focus:ring-primary-500"
                                        />
                                        <label
                                          htmlFor={`${field}-${option.value}`}
                                          className="ml-2 block text-sm text-gray-700"
                                        >
                                          {option.label}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                  {selectedEventConfig[field]?.description && (
                                    <p className="mt-1 text-sm text-gray-500">
                                      {selectedEventConfig[field].description}
                                    </p>
                                  )}
                                </div>
                              );
                            }
                            return null;
                          }
                        )}
                        {(!formData.events.some((e) => e.name === selectedEventName) ||
                          !formData.events.find((e) => e.name === selectedEventName)
                            ?.custom_config ||
                          !Object.keys(
                            formData.events.find((e) => e.name === selectedEventName)
                              ?.custom_config || {}
                          ).every(
                            (key) =>
                              formData.events.find((e) => e.name === selectedEventName)
                                .custom_config[key].value === selectedEventConfig[key].value
                          )) && (
                            <div className="flex flex-row-reverse gap-2">
                              <Button
                                onClick={() => {
                                  const requiredEvent = formData.events.find(
                                    (e) => e.name === selectedEventName
                                  );
                                  const updatedEvents = formData.events.filter(
                                    (e) => e.name !== selectedEventName
                                  );
                                  const updatedEventPayload = {
                                    name: selectedEventName,
                                    zones: polygons,
                                    custom_config: selectedEventConfig,
                                    label: scenarioInfo.events[selectedEventName].label
                                  };
                                  if (requiredEvent) {
                                    updatedEventPayload.zones = requiredEvent.zones;
                                  } else {
                                    updatedEventPayload.zones = polygons;
                                  }
                                  updatedEvents.push(updatedEventPayload);
                                  dispatch(
                                    setScenarioPerCamFormData({
                                      ...formData,
                                      events: updatedEvents
                                    })
                                  );
                                }}
                                variant="primary"
                              >
                                Confirm
                              </Button>
                            </div>
                          )}
                      </div>
                    </div>
                  )}

                <div className="">
                  <div className="mx-6 mt-6">
                    <div className="flex flex-row-reverse gap-2 pb-2">
                      <Button
                        variant="primary"
                        onClick={() => {
                          if (!scenarioInfo.event_based_zones && scenarioInfo.zone_required) {
                            if (
                              polygons.length === 0 ||
                              (polygons.length === 1 && polygons[0].length === 0)
                            ) {
                              return api.info({
                                message: 'No Zone Created',
                                description: 'Zones are mandatory for this scenario.'
                              });
                            }
                          }
                          if (
                            scenarioInfo.event_based_zones &&
                            scenarioInfo.events[selectedEventName] &&
                            scenarioInfo.events[selectedEventName]['zone_required']
                          ) {
                            if (
                              (polygons.length === 1 && polygons[0].length !== 0) ||
                              (isPolygonCompleteArr.length === 1 &&
                                isPolygonCompleteArr[0] !== false) ||
                              currentPolygonIdx !== 0
                            ) {
                              return api.warning({
                                message: 'Please save created zones',
                                placement: 'topRight'
                              });
                            }
                          }

                          if (scenarioInfo.event_based_zones) {
                            if (!makeViewOnly) {
                              if (
                                (polygons.length === 1 && polygons[0].length === 0) ||
                                (isPolygonCompleteArr.length === 1 &&
                                  isPolygonCompleteArr[0] === false) ||
                                currentPolygonIdx === 0
                              ) {
                                return api.warning({
                                  message: 'Please draw zones before proceeding',
                                  placement: 'topRight'
                                });
                              }
                            }
                          }
                          if (formData.events.length === 0) {
                            api.info({
                              message: 'No Events Selected',
                              description: 'Please select an event to apply the scenario.'
                            });
                            return;
                          }
                          const updatedEvents = formData.events.filter(
                            (e) => e.name !== scenarioInfo.name
                          );

                          if (!scenarioInfo.event_based_zones) {
                            updatedEvents.push({
                              name: scenarioInfo.name,
                              zones: [...polygons],
                              custom_config: { ...selectedEventConfig },
                              label: scenarioInfo.name
                            });
                            dispatch(
                              setScenarioPerCamFormData({
                                ...formData,
                                events: updatedEvents
                              })
                            );
                          }

                          setNextStep(true);
                        }}
                      >
                        Next {'>'}
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          navigateTo(`/cameras/${searchParams.get('cameraId')}`);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ApplyScenarioForm;
