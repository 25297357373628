import { Button, TextField } from '@shopify/polaris';
import { useDropzone } from 'react-dropzone';
import Switch from '../../components/Switch';
import { getEmailRegex } from '../../utils';



const SiteForm = ({ formData, setFormData, isEmailValid, setIsEmailValid, onDrop }) => {
  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: {
      'image/*': []
    },
    multiple: false
  });

  const dropzoneClasses = `
    flex-1 flex flex-col items-center justify-center p-5 
    border-2 border-dashed rounded-md bg-gray-100
${formData.sitemapImage ? 'border-green-500' : isDragAccept ? 'border-green-500' : isDragReject ? 'border-red-500' : 'border-gray-300'}
  `;
  return (
    <div>
      <style>
        {`.Polaris-TextField {
                margin-top: 10px;
            }`}
      </style>
      <div className="w-full">
        <div className="flex items-center mb-2">
          <span className="flex-1">
            Full Name<span className="text-red-500">*</span>
          </span>

          <span className="w-1/2 lg:w-3/4">
            <TextField
              value={formData.name}
              onChange={(value) => {
                if (value.length > 100) return;
                setFormData({ ...formData, name: value });
              }}
              labelHidden
              placeholder="Benjamin Bookcase"
            />
          </span>
        </div>

        <div className="flex items-center mb-2">
          <span className="flex-1">
            Email<span className="text-red-500">*</span>
          </span>

          <span className="w-1/2 lg:w-3/4">
            <TextField
              value={formData.email}
              onChange={(value) => {
                if (value.length > 200) return;
                const regex = getEmailRegex();
                if (!regex.test(value)) {
                  setIsEmailValid(false);
                } else {
                  setIsEmailValid(true);
                }
                setFormData({ ...formData, email: value });
              }}
              labelHidden
              placeholder="bbookcase@visionify.ai"
            />
            {!isEmailValid && (
              <span className="text-red-500 text-xs mt-2">Invalid Email Address</span>
            )}
          </span>
        </div>
        <div className="flex mb-2">
          <span className="flex-1">
            Address<span className="text-red-500">*</span>
          </span>

          <span className="w-1/2 lg:w-3/4">
            <TextField
              value={formData.addressLn1}
              onChange={(value) => {
                if (value.length > 200) return;
                setFormData({ ...formData, addressLn1: value });
              }}
              labelHidden
              placeholder="123 Palo Alto St"
            />

            <TextField
              value={formData.addressLn2}
              onChange={(value) => {
                if (value.length > 200) return;
                setFormData({ ...formData, addressLn2: value });
              }}
              labelHidden
              placeholder="Unit 23"
            />
            <div className="flex justify-between">
              <span className="w-3/4">
                <TextField
                  value={formData.state}
                  onChange={(value) => {
                    if (value.length > 50) return;
                    setFormData({ ...formData, state: value });
                  }}
                  labelHidden
                  placeholder="Colorado"
                />
              </span>
              <span className="w-1/4 mx-2">
                <TextField
                  value={formData.zipcode}
                  onChange={(value) => {
                    if (value.length > 10) return;
                    if (isNaN(value)) return;
                    if (value[value.length - 1] === '.') return;
                    setFormData({ ...formData, zipcode: value });
                  }}
                  labelHidden
                  placeholder="95112"
                />
              </span>
            </div>
          </span>
        </div>

        <div className="border-t border-gray-200 pt-4 mt-4">
          <div className="mb-4">
            <h3 className="text-lg font-medium text-gray-900">Privacy Settings</h3>
            <p className="mt-1 text-sm text-gray-500">
              Configure privacy options for video processing
            </p>
          </div>

          <div className="flex flex-nowrap justify-start items-center gap-4 mb-2">
            <div className="flex items-center w-auto border-r pr-4">
              <span className="mr-2 whitespace-nowrap">
                Face Blur<span className="text-red-500">*</span>
              </span>
              <Switch
                onColor="green"
                id="react-switch-faceblur"
                isOn={formData.faceBlur}
                handleToggle={() => {
                  setFormData({ ...formData, faceBlur: !formData.faceBlur });
                }}
              />
            </div>

            <div className="flex items-center w-auto border-r pr-4">
              <span className="mr-2 whitespace-nowrap">
                Full Body Blur<span className="text-red-500">*</span>
              </span>
              <Switch
                onColor="green"
                id="react-switch-fullbodyblur"
                isOn={formData.fullBodyBlur}
                handleToggle={() => {
                  setFormData({ ...formData, fullBodyBlur: !formData.fullBodyBlur });
                }}
              />
            </div>

            <div className="flex items-center w-auto">
              <span className="mr-2 whitespace-nowrap">
                Stick Figure<span className="text-red-500">*</span>
              </span>
              <Switch
                id="react-switch-stickfigure"
                onColor="green"
                isOn={formData.stickFigure}
                handleToggle={() => {
                  setFormData({ ...formData, stickFigure: !formData.stickFigure });
                }}
              />
            </div>
          </div>
        </div>
        {/* <div className="border-t border-gray-200 pt-4 mt-4">
          <div className="mb-4">
            <h3 className="text-lg font-medium text-gray-900">Notification Settings</h3>
            <p className="mt-1 text-sm text-gray-500">
              Configure notification preferences for different event categories
            </p>
          </div>

          <div className="flex flex-col space-y-8"> */}
            {/* Web App Notifications */}
            {/* <div className="flex items-center gap-4">
              <div className="flex items-center min-w-[250px] border-r pr-4">
                <span className="font-medium text-gray-900 mr-3">
                  Web App Notifications<span className="text-red-500">*</span>
                </span>
                <Switch
                  onColor="green"
                  id="react-switch-webapp"
                  isOn={formData.webAppNotifications}
                  handleToggle={() => {
                    setFormData({ ...formData, webAppNotifications: !formData.webAppNotifications });
                  }}
                />
              </div>
              
              {formData.webAppNotifications && (
                <div className="w-[300px]">
                  <Select
                    placeholder="Choose notification categories"
                    options={[
                      { label: 'Emergency Events', value: 'emergency_events' },
                      { label: 'Worker Health & Safety', value: 'worker_health_safety' },
                      { label: 'Area Controls', value: 'area_controls' },
                      { label: 'Vehicle Control', value: 'vehicle_control' },
                      { label: 'Behavioral Safety', value: 'behavioral_safety' },
                      { label: 'Housekeeping', value: 'housekeeping' }
                    ]}
                    onChange={(selected) => {
                      setFormData({
                        ...formData,
                        webAppNotificationCategories: selected
                      });
                    }}
                    value={formData.webAppNotificationCategories}
                    multiple
                  />
                </div>
              )}
            </div> */}

            {/* Announcement Notifications */}
            {/* <div className="flex items-center gap-4">
              <div className="flex items-center min-w-[250px] border-r pr-4">
                <span className="font-medium text-gray-900 mr-3">
                  Announcement Notifications<span className="text-red-500">*</span>
                </span>
                <Switch
                  onColor="green"
                  id="react-switch-announcement"
                  isOn={formData.announcementNotifications}
                  handleToggle={() => {
                    setFormData({ ...formData, announcementNotifications: !formData.announcementNotifications });
                  }}
                />
              </div>
              
              {formData.announcementNotifications && (
                <div className="w-[300px]">
                  <Select
                    placeholder="Choose notification categories"
                    options={[
                      { label: 'Emergency Events', value: 'emergency_events' },
                      { label: 'Worker Health & Safety', value: 'worker_health_safety' },
                      { label: 'Area Controls', value: 'area_controls' },
                      { label: 'Vehicle Control', value: 'vehicle_control' },
                      { label: 'Behavioral Safety', value: 'behavioral_safety' },
                      { label: 'Housekeeping', value: 'housekeeping' }
                    ]}
                    onChange={(selected) => {
                      setFormData({
                        ...formData,
                        announcementNotificationCategories: selected
                      });
                    }}
                    value={formData.announcementNotificationCategories}
                    multiple
                  />
                </div>
              )}
            </div>
          </div>
        </div> */}

        <div className="border-t border-gray-200 pt-4 mt-4">
          <div className="mb-4">
            <h3 className="text-lg font-medium text-gray-900">Sitemap Image</h3>
            <p className="mt-1 text-sm text-gray-500">
              Upload a sitemap image for your location
            </p>
          </div>

          <div className="flex center mb-2">
            <span className="flex-1">
              Upload Image<span className="text-red-500">*</span>
            </span>

            <span className="w-1/2 lg:w-3/4">
              <div {...getRootProps()} className={dropzoneClasses}>
                <p>
                  {formData.sitemapImage ? (
                    <div className="flex flex-col w-full">
                      {typeof formData.sitemapImage === 'string' ? (
                        <p>
                          You have already uploaded a sitemap. Clear it if you want to reupload a new
                          sitemap.
                        </p>
                      ) : (
                        <p>{formData.sitemapImage.name} has successfully been uploaded!</p>
                      )}
                    </div>
                  ) : isDragAccept ? (
                    'All files are accepted'
                  ) : isDragReject ? (
                    'Some files are rejected'
                  ) : (
                    "Drag 'n' drop your sitemap here"
                  )}
                </p>
                <input {...getInputProps()} />
              </div>
              {formData.sitemapImage && (
                <div className="mt-1 w-full flex flex-row-reverse">
                  <Button
                    onClick={() => setFormData({ ...formData, sitemapImage: null })}
                    variant="primary"
                    tone="critical"
                  >
                    Clear
                  </Button>
                </div>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteForm;
