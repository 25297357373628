/* eslint-disable  */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  DataTable,
  Label,
  TextField,
  ProgressBar,
  Spinner,
  Badge,
  DatePicker,
  Pagination
} from '@shopify/polaris';
import { MobileBackArrowMajor, PlusMinor } from '@shopify/polaris-icons';
import { Modal, notification, Popover } from 'antd';
import usersolid from '../../assets/user-solid.svg';
import calendarsolid from '../../assets/calendar-solid.svg';
import { Bar, Line } from 'react-chartjs-2';
import { PlayMinor } from '@shopify/polaris-icons';
import StatusDropdown from './StatusDropdown';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createTaskForCampaign, getCampaignById } from '../../api/campaigns.api';
import { formatDate, formatTimeFromSeconds } from '../../utils';
import { StatCard } from '../Dashboard';
import MultiSwitch from '../../components/MultiSwitch';

const EventCard = ({ event }) => {
  const navigateTo = useNavigate();
  return (
    <div className="p-4 bg-white rounded-lg shadow-md border border-gray-200 hover:shadow-lg transition-shadow">
      <div className="space-y-3">
        <div className="aspect-video bg-gray-200 rounded-md overflow-hidden">
          {/* Video thumbnail or preview */}
          {/* <a href={event.thumbnail_url} target="_blank"> */}
         

            <img
              onClick={() => {
                navigateTo(`/events/${event.event_id}?type=campaign`);
              }}
              src={event.thumbnail_url}
              alt={event.eventName}
              className="w-full h-full object-cover"
            />
          {/* </a> */}
        </div>
        <div className="space-y-2">
          <h3 className="font-semibold text-lg">{event.eventName}</h3>
          <div className="grid grid-cols-2 gap-2 text-sm">
            <div className="text-gray-600">Area:</div>
            <div>{event.area}</div>
            <div className="text-gray-600">Camera:</div>
            <div>{event.camera_name}</div>
            <div className="text-gray-600">Severity:</div>
            <div
              className={`font-medium ${event.severity === 'High'
                  ? 'text-red-600'
                  : event.severity === 'Medium'
                    ? 'text-yellow-600'
                    : 'text-green-600'
                }`}
            >
              {event.severity}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EventGrid = ({ events }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = 8;

  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = events.slice(indexOfFirstEvent, indexOfLastEvent);

  const handlePrevious = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    setCurrentPage((prev) => prev + 1);
  };

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {currentEvents.map((event, index) => (
          <EventCard key={event.id || index} event={event} />
        ))}
      </div>

      <div className="flex justify-center">
        <Pagination
          hasPrevious={currentPage > 1}
          onPrevious={handlePrevious}
          hasNext={indexOfLastEvent < events.length}
          onNext={handleNext}
        />
      </div>
    </div>
  );
};

const ViewCampaign = () => {
  const navigateTo = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  const params = useParams();

  const {
    data: campaign,
    isLoading,
    refetch
  } = useQuery({
    queryKey: ['campaign-info', params.id],
    queryFn: async () => {
      const response = await getCampaignById(params.id);
      return response.data;
    }
  });

  const { mutate: createTask } = useMutation({
    mutationFn: createTaskForCampaign,
    onSuccess: () => {
      SetIsOpen(false);
      handleDescriptionChange('');
      handleTaskNameChange('');
      setEndDate(new Date());
      setEndDateInputValue('');
      refetch();
    },
    onError: () => {
      return api.error('Something went wrong! Please contact support@visionify.ai');
    }
  });

  useEffect(() => {
    if (campaign) {
      setTasks([...campaign.tasks]);
      setVideos([
        ...campaign.videos.map((e) => [
          <Button
            onClick={() => {
              navigateTo(`/events/${e.event_id}?type=campaign`);
            }}
            icon={PlayMinor}
            key="Video"
          >
            Video Clip
          </Button>,
          e.area,
          e.camera_name,
          <Badge>{e.severity}</Badge>
        ])
      ]);
      setVideoEventsList([...campaign.videos]);
    }
  }, [campaign]);

  const [tasks, setTasks] = useState([]);

  const [videos, setVideos] = useState([]);
  const [videoEventsList, setVideoEventsList] = useState([]);

  const [viewIdx, setViewIdx] = useState(0); // 0 - list, 1- grid

  const changeView = (view) => {
    if (view === 'List') {
      setViewIdx(0);
    } else {
      setViewIdx(1);
    }
  };

  const handleStatusChange = (newStatus, taskId) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) => (task.id === taskId ? { ...task, status: newStatus } : task))
    );
  };

  const rows = tasks.map((task, idx) => [
    idx + 1,
    task.title,
    task.description,
    formatDate(task.end_by),
    <StatusDropdown
      key={task.id}
      id={task.id}
      refetch={refetch}
      currentStatus={task.status}
      onChange={(newStatus) => handleStatusChange(newStatus, task.id)}
    />,
    <div key={task.id} className="flex items-center justify-between gap-5">
      <ProgressBar progress={task.progress} size="small" tone="primary" />
      <p className="flex-none">{task.progress}%</p>
    </div>
  ]);

  const eventData = {
    labels: ['', '', '', '', '', '', ''],
    datasets: [
      {
        data: campaign?.metrics?.tasksInitiated ?? [],
        borderColor: '#2D5BFF',
        borderWidth: 3,
        fill: false,
        pointRadius: 0, // Remove points
        tension: 0 // Disable spline for straight lines
      }
    ]
  };

  const IncidentsData = {
    labels: ['', '', '', '', '', '', ''],
    datasets: [
      {
        data: campaign?.metrics?.videos ?? [],
        borderColor: '#04CE00',
        borderWidth: 3,
        fill: false,
        pointRadius: 0, // Remove points
        tension: 0 // Disable spline for straight lines
      }
    ]
  };
  const [open, SetIsOpen] = useState(false);

  const [taskDescription, setTaskDescription] = useState('');
  const handleDescriptionChange = useCallback((newValue) => {
    if (newValue.length > 800) return;
    setTaskDescription(newValue);
  }, []);

  const [taskName, setTaskName] = useState('');
  const handleTaskNameChange = useCallback((newValue) => {
    if (newValue.length > 100) return;
    setTaskName(newValue);
  }, []);

  const taskRows = rows.map((row) => {
    return [...row];
  });

  const chartoptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false // Hide legend
      },
      tooltip: {
        enabled: false // Hide tooltip
      }
    },
    scales: {
      x: {
        display: false // Hide X axis
      },
      y: {
        display: false // Hide Y axis
      }
    }
  };

  const barchartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltips: {
        enabled: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          borderDash: [3, 3], // Make Y axis grid lines dotted
          borderDashOffset: 2
        }
      },
      y: {
        grid: {
          color: 'rgba(0, 0, 0, 0.1)', // Color of Y axis grid lines
          borderDash: [3, 3], // Make Y axis grid lines dotted
          borderDashOffset: 2
        },
        ticks: {
          stepSize: 30 // Adjust this value to control grid line spacing
        }
      }
    }
  };

  const [chartData, setChartData] = useState({
    labels: campaign?.metrics?.completedTasksLast10Days?.label ?? [],
    datasets: [
      {
        label: '',
        data: campaign?.metrics?.completedTasksLast10Days?.data ?? [],
        hoverOffset: 2,
        maxBarThickness: 40,
        borderRadius: 8
      }
    ]
  });

  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current;
      const ctx = chart.ctx;
      const gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, 'rgba(225, 202, 255, 1)');
      gradient.addColorStop(1, 'rgba(156, 106, 222, 1)');

      setChartData((prevData) => ({
        ...prevData,
        datasets: prevData.datasets.map((dataset) => ({
          ...dataset,
          backgroundColor: gradient // Apply the gradient as background color
        }))
      }));
    }
  }, [chartRef]);

  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());

  const [endDate, setEndDate] = useState(new Date());
  const [endDateInputValue, setEndDateInputValue] = useState('');
  const [popoverActive2, setPopoverActive2] = useState(false);

  const togglePopoverActive2 = useCallback(
    () => setPopoverActive2((popoverActive2) => !popoverActive2),
    []
  );

  const handleEndDateChange = useCallback(
    (date) => {
      setEndDate(date.start);
      const formattedDate = `${date.start.toLocaleDateString()}`;
      setEndDateInputValue(formattedDate);
      togglePopoverActive2(); // Close the calendar after selecting the date
    },
    [togglePopoverActive2]
  );

  const handleMonthChange = useCallback((month, year) => {
    setMonth(month);
    setYear(year);
  }, []);

  if (isLoading) {
    return (
      <div className="grid place-items-center h-screen w-screen">
        <Spinner size="large" />
      </div>
    );
  }

  return (
    <>
      <div className="radiousnone main-title">
        {contextHolder}
        <div className="bg-white py-3 px-3 shadow-md border-b">
          <div className="title smallbtn flex items-center gap-3">
            <MobileBackArrowMajor onClick={() => navigateTo(-1)} className="w-6 cursor-pointer" />
            <h3 className="text-black text-lg font-semibold">{campaign.title}</h3>
          </div>
        </div>
      </div>
      <div className="px-4 py-4">
        <Card>
          <div className="title bg-[#F7F4FF] rounded-[12px]">
            <div className="flex justify-between">
              <div className="py-3 px-3 flex items-center flex-wrap gap-3">
                <div className="bg-[#8670C5] rounded-[5px]">
                  <p className="text-white text-sm font-semibold text-center py-1">
                    {campaign.date}
                  </p>
                  <h3 className="bg-[#303030] text-[12px] font-medium text-white px-2 py-1 rounded-b-[5px]">
                    {campaign.monthyear}
                  </h3>
                </div>
                <div className="title">
                  <p className="text-sm font-semibold text-[#303030]">{campaign.title}</p>
                  <div className="flex items-center flex-wrap flex-none gap-3">
                    <div>
                      <p className="text-[12px] pt-2 text-[#303030] flex items-center gap-2">
                        <img className="w-[15px]" src={usersolid} />
                        {campaign.organizer}{' '}
                      </p>
                    </div>
                    <span className="w-1 h-1 mt-2 bg-[#616161] rounded-full"></span>
                    <div>
                      <p className="text-[12px] pt-2 text-[#303030] flex items-center gap-2">
                        <img className="w-[15px]" src={calendarsolid} />
                        {campaign.duration}{' '}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {campaign.description && (
            <div className="w-full mt-3">
              <div className="pb-1">
                <Label>Description</Label>
              </div>
              <div className="w-full relative">
                <TextField
                  type="text"
                  labelHidden
                  multiline={4}
                  value={campaign.description}
                  autoComplete="off"
                />
              </div>
            </div>
          )}
          <div className="cus_table_design padding0 mt-4">
            <Card>
              <div className="flex items-center justify-between">
                <h3 className="font-semibold text-[18px]">Tasks</h3>
                <Button onClick={() => SetIsOpen(true)} icon={PlusMinor} key="Inferencevideo">
                  Create New Task
                </Button>
              </div>
              {tasks && tasks.length ? (
                <DataTable
                  verticalAlign="middle"
                  columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text']}
                  headings={[
                    'Task Number',
                    'Title',
                    'Description',
                    'Due By',
                    'To-Do Status',
                    'Progress'
                  ]}
                  rows={taskRows}
                  hasZebraStripingOnData
                />
              ) : (
                <div className="h-32 grid place-items-center">
                  No Tasks Assigned for this Campaign yet!
                </div>
              )}
            </Card>
          </div>
          <div className="cus_table_design padding0 mt-4">
            <Card>
              <div className="flex items-center justify-between">
                <h3 className="font-semibold text-[18px]">Events</h3>
                <span className="px-2">
                  <MultiSwitch
                    options={['List', 'Grid']}
                    selectedOption={viewIdx}
                    bgColors={['bg-black/10', 'bg-black/10']}
                    textColors={['text-black', 'text-black']}
                    onSelect={changeView}
                    fullWidth={false}
                  />
                </span>
              </div>
              {viewIdx === 0 ? (
                videos && videos.length ? (
                  // for list view
                  <DataTable
                    verticalAlign="middle"
                    columnContentTypes={['text', 'text', 'text', 'text', 'text']}
                    headings={['Event', 'Area', 'Camera', 'Training']}
                    rows={videos}
                    hasZebraStripingOnData
                  />
                ) : (
                  <div className="h-32 grid place-items-center">
                    No Events have been assigned for this Campaign yet!
                  </div>
                )
              ) : videoEventsList && videoEventsList.length ? (
                // for grid view
                <EventGrid events={videoEventsList} />
              ) : (
                <div className="h-32 grid place-items-center">
                  No Events have been assigned for this Campaign yet!
                </div>
              )}
            </Card>
          </div>
          {campaign.status !== 'Upcoming' && (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-x-3 my-2 gap-y-2">
              <StatCard
                title={'Total Events'}
                trend={
                  campaign.statistics.totalEvents.percentageChange > 0 ? 'POSITIVE' : 'NEGATIVE'
                }
                value={campaign.statistics.totalEvents.campaign}
                trendNumber={Math.abs(campaign.statistics.totalEvents.percentageChange)}
                inverseTrendBenefit={true}
              />
              <StatCard
                title={'Time Between Events'}
                trend={
                  campaign.statistics.avgTimeBetweenEvents.percentageChange > 0
                    ? 'POSITIVE'
                    : 'NEGATIVE'
                }
                value={formatTimeFromSeconds(
                  campaign.statistics.avgTimeBetweenEvents.campaign * 3600
                )}
                trendNumber={Math.abs(campaign.statistics.avgTimeBetweenEvents.percentageChange)}
              />
              <StatCard
                title={'Reviewed Events'}
                trend={
                  campaign.statistics.reviewedEvents.percentageChange > 0 ? 'POSITIVE' : 'NEGATIVE'
                }
                value={campaign.statistics.reviewedEvents.campaign}
                trendNumber={Math.abs(campaign.statistics.reviewedEvents.percentageChange)}
              />
            </div>
          )}
          <div className="grid xl:grid-cols-7 mt-4 gap-5">
            <div className="xl:col-span-2">
              <Card padding={0}>
                <div className="title border-b px-3 py-3">
                  <h3 className="font-semibold text-[18px]">Start Day Metrics</h3>
                </div>
                <div className="space-y-5 px-5 py-5">
                  <Card>
                    <div className="flex items-center justify-between gap-2">
                      <div className="space-y-5">
                        <h4 className="text-[18px] text-[#1A1A1A] font-semibold pt-3">
                          Tasks Initiated
                        </h4>
                        <h2 className="text-[34px] text-[#1A1A1A] font-bold pt-5 pb-3">
                          {campaign.metrics.tasksInitiated.reduce((acc, curr) => {
                            return acc + curr;
                          }, 0)}
                        </h2>
                      </div>
                      <div className="">
                        <Line
                          height={'70px'}
                          width={'180px'}
                          data={eventData}
                          options={chartoptions}
                        />
                      </div>
                    </div>
                  </Card>
                  <Card>
                    <div className="flex items-center justify-between gap-2">
                      <div className="space-y-5">
                        <h4 className="text-[18px] text-[#1A1A1A] font-semibold pt-3">
                          Campaign Metrics
                        </h4>
                        <h2 className="text-[34px] text-[#1A1A1A] font-bold pt-5 pb-3">
                          {campaign.metrics.videos.reduce((acc, curr) => {
                            return acc + curr;
                          }, 0)}
                        </h2>
                      </div>
                      <div className="">
                        <Line
                          height={'70px'}
                          width={'180px'}
                          data={IncidentsData}
                          options={chartoptions}
                        />
                      </div>
                    </div>
                  </Card>
                </div>
              </Card>
            </div>
            <div className="xl:col-span-5">
              <Card padding={0}>
                <div className="title border-b px-3 py-3">
                  <h3 className="font-semibold text-[18px]">Task Metrics</h3>
                </div>
                <div className="px-3 py-3">
                  <div className="">
                    <div className="flex items-center gap-5 px-2">
                      <div>
                        <p className="text-[12px] text-[#616161]">
                          Completion rates in the last 10 days.
                        </p>
                      </div>
                    </div>
                    <div
                      className="bar-chart-container mx-[10px] py-5 pb-0"
                      style={{ height: '270px' }}
                    >
                      <Bar ref={chartRef} data={chartData} options={barchartoptions} />
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </Card>
      </div>

      <Modal
        title="Create Task"
        width={800}
        open={open}
        onCancel={() => SetIsOpen(false)}
        footer={[
          <div className={`inline mr-2`} key="cancel">
            <Button
              variant="secondary"
              size="large"
              onClick={() => {
                handleTaskNameChange('');
                handleDescriptionChange('');
                setEndDate(new Date());
                setEndDateInputValue('');
                SetIsOpen(false);
              }}
            >
              Cancel{' '}
            </Button>
          </div>,
          <div className="inline mr-2" key="submit">
            <Button
              onClick={async () => {
                if (!taskName) {
                  return api.warning({ message: 'Task Title is mandatory!' });
                }
                if (!taskDescription) {
                  return api.warning({ message: 'Task Description is mandatory!' });
                }
                if (!endDateInputValue) {
                  return api.warning({ message: 'Due Date is mandatory!' });
                }
                const payload = {
                  title: taskName,
                  description: taskDescription,
                  started_at: null,
                  end_at: null,
                  end_by: endDate,
                  status: 'TODO'
                };
                createTask({ id: params.id, payload });
              }}
              variant="primary"
              size="large"
            >
              <div className="flex items-center">Save</div>
            </Button>
          </div>
        ]}
      >
        <div className="form mt-10">
          <div className="w-full">
            <div className="flex items-center mb-5 gap-5">
              <span className="flex-1">Title</span>
              <span className="w-full lg:w-3/4">
                <TextField
                  value={taskName}
                  onChange={handleTaskNameChange}
                  labelHidden
                  placeholder="Enter Title"
                />
              </span>
            </div>

            <div className="flex items-center mb-5 gap-5">
              <span className="flex-1">Description</span>

              <span className="w-full lg:w-3/4">
                <TextField
                  labelHidden
                  value={taskDescription}
                  onChange={handleDescriptionChange}
                  multiline={4}
                  placeholder="Enter Description"
                  autoComplete="off"
                />
              </span>
            </div>

            <div className="flex items-center mb-5 gap-5">
              <span className="flex-1">Due By</span>
              <span className="w-full lg:w-3/4">
                <TextField
                  labelHidden
                  placeholder="Select Due Date"
                  value={endDateInputValue}
                  onFocus={togglePopoverActive2}
                  onClick={togglePopoverActive2} // Trigger popover on click
                  readOnly // Make the field read-only to prevent manual input
                />
                {popoverActive2 && (
                  <Popover
                    active={popoverActive2}
                    activator={<div />}
                    onClose={togglePopoverActive2}
                    preferInputActivator={false}
                  >
                    <div className="py-3 px-3">
                      <DatePicker
                        month={month}
                        year={year}
                        onChange={handleEndDateChange}
                        onMonthChange={handleMonthChange}
                        selected={{ start: endDate, end: endDate }}
                      />
                    </div>
                  </Popover>
                )}
              </span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewCampaign;
