// show all notifications on selection of notification icon
import { useSelector } from 'react-redux';
import { Text, Badge, Icon, Card, Button, Tooltip } from '@shopify/polaris';
import { CircleTickMajor, CircleCancelMajor } from '@shopify/polaris-icons';

const formatTimestamp = (isoTimestamp) => {
    try {
        if (isoTimestamp== undefined) {
            isoTimestamp = new Date().toISOString();
        }
        const date = new Date(isoTimestamp);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short'
        };
        
        return date.toLocaleString('en-US', options);
        // Output: "January 20, 2025, 07:29:06 AM GMT"
    } catch (error) {
        return isoTimestamp;
    }
};

const ImagePreview = ({ imageData }) => {
    if (!imageData) return null;

    return (
        <img
            src={imageData}
            alt="Event"
            style={{
                width: '120px',
                height: '80px',
                objectFit: 'cover',
                borderRadius: '8px',
                cursor: 'pointer'
            }}
            onClick={() => {
                const newWindow = window.open();
                newWindow.document.write(`
                    <html>
                        <head>
                            <title>Event Image</title>
                            <style>
                                body {
                                    margin: 0;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    min-height: 100vh;
                                    background: #000;
                                }
                                img {
                                    max-width: 100%;
                                    max-height: 100vh;
                                    object-fit: contain;
                                }
                            </style>
                        </head>
                        <body>
                            <img src="${imageData}" alt="Event Image"/>
                        </body>
                    </html>
                `);
            }}
            onError={(e) => {
                console.error('Failed to load image');
                e.target.style.display = 'none';
            }}
        />
    );
};

const NotificationList = ({ open, onClose, markNotificationAsRead, dismissAll }) => {
    const notifications = useSelector((state) => state.notifications?.notifications || []);

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            width: '500px',  // Fixed width
            backgroundColor: 'white',
            boxShadow: '-2px 0 8px rgba(0, 0, 0, 0.1)',
            transform: `translateX(${open ? '0' : '100%'})`,
            transition: 'transform 0.3s ease-in-out',
            zIndex: 999,
            overflowY: 'auto'
        }}>
            <div style={{
                padding: '16px',
                borderBottom: '1px solid var(--p-border-subdued)',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
                zIndex: 1
            }}>
                <Text variant="headingLg">Notifications</Text>
                <div style={{
                    display: 'flex',
                    gap: '16px',  // Space between buttons
                    alignItems: 'center'
                }}>
                    <Button 
                        plain 
                        onClick={dismissAll}
                        tone="critical"
                    >
                        Dismiss All
                    </Button>
                    <div style={{ 
                        width: '1px', 
                        height: '20px', 
                        backgroundColor: 'var(--p-border-subdued)' 
                    }} />
                    <Button 
                        plain 
                        onClick={onClose}
                    >
                        Close
                    </Button>
                </div>
            </div>

            <div style={{ 
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px'
            }}>
                {notifications.map((notification, index) => (
                    <Card key={notification.id || index}>
                        <div style={{
                            padding: '16px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '12px',
                            position: 'relative'
                        }}>
                            {/* Close Button with Tooltip */}
                            <Tooltip content="Mark as read">
                                <span
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        markNotificationAsRead(notification);
                                    }}
                                    style={{
                                        position: 'absolute',
                                        top: '4px',
                                        right: '8px',
                                        cursor: 'pointer',
                                        opacity: 0.8,        // Increased opacity for better visibility
                                        padding: '4px',
                                        fontSize: '18px',    // Increased font size
                                        lineHeight: 1,
                                        userSelect: 'none',
                                        color: 'var(--p-text-critical)',  // Polaris red color
                                        fontWeight: '500',   // Made slightly bolder
                                        transition: 'all 0.2s ease',  // Smooth hover transition
                                        ':hover': {
                                            opacity: 1,
                                            transform: 'scale(1.1)'   // Slight grow on hover
                                        }
                                    }}
                                >
                                    ✕
                                </span>
                            </Tooltip>

                            {/* Header: Status and Timestamp */}
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingRight: '32px'
                            }}>
                                <div style={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    gap: '8px' 
                                }}>
                                    <Icon
                                        source={notification.read ? CircleTickMajor : CircleCancelMajor}
                                        tone={notification.read ? "success" : "critical"}
                                    />
                                    <Text variant="bodyMd" fontWeight="bold">
                                        {formatTimestamp(notification.timestamp)}
                                    </Text>
                                </div>
                            </div>

                            {/* Camera and Scenario Info */}
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start'
                            }}>
                                <div style={{ flex: 1 }}>
                                    <Text variant="headingMd" as="h3">
                                        Camera: {notification.camera_name}
                                    </Text>
                                    <Text variant="bodyMd" tone="subdued">
                                        Scenario: {notification.scenario_name}
                                    </Text>
                                </div>

                                {/* Event Image */}
                                {notification.image_url && <ImagePreview imageData={notification.image_url} />}
                            </div>

                            {/* Event Names */}
                            <div style={{
                                display: 'flex',
                                gap: '8px',
                                flexWrap: 'wrap'
                            }}>
                                {notification.event_names?.map((event, eventIndex) => (
                                    <Badge
                                        key={`${notification.id}-event-${eventIndex}`}
                                        tone={event.toLowerCase().includes('no') ? 'critical' : 'success'}
                                    >
                                        {event}
                                    </Badge>
                                ))}
                            </div>
                        </div>
                    </Card>
                ))}

                {notifications.length === 0 && (
                    <div style={{
                        textAlign: 'center',
                        padding: '32px',
                        color: 'var(--p-text-subdued)'
                    }}>
                        <Text variant="bodyLg">No notifications available</Text>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NotificationList;
