// notificationSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notifications: [],
  unreadCount: 0,
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action) => {
      
        state.notifications.push(
            action.payload
        );
        state.unreadCount += 1;
    
    },
    clearNotifications: (state) => {
      state.notifications = [];
      state.unreadCount = 0;
    },
    markAsRead: (state, action) => {
      // remove the notification from the notifications array
      state.notifications = state.notifications.filter(n => n.id !== action.payload.id);
      state.unreadCount -= 1;

    }
  }
});

export const { addNotification, clearNotifications, markAsRead } = notificationSlice.actions;
export default notificationSlice.reducer;