import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  events: [],
  selectedReviewType: '2',
  selectedCamera: 'All',
  selectedTag: 'All',
  selectedScenarioEvents: ['All'],
  selectedScenarios: ['All'],
  selectedDates: {
    start: (() => {
      const date = new Date();
      date.setDate(date.getDate() - 7);
      date.setHours(0, 0, 0, 0);
      return date;
    })(),
    end: (() => {
      const date = new Date();
      date.setHours(23, 59, 59, 999);
      return date;
    })()
  },
  chosenEventIdx: null,
  timezone: null,
  chosenDateIdx: null,
  chosenDate: null,
  chosenDateEvents: [],
  doesDateHaveMore: null
};

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setCamera: (state, action) => {
      state.selectedCamera = action.payload;
    },
    setTag: (state, action) => {
      state.selectedTag = action.payload;
    },
    setSelectedReviewType: (state, action) => {
      state.selectedReviewType = action.payload;
    },
    setSelectedDates: (state, action) => {
      state.selectedDates = action.payload;
    },
    setSelectedScenarioEvents: (state, action) => {
      state.selectedScenarioEvents = action.payload;
    },
    setSelectedScenarios: (state, action) => {
      state.selectedScenarios = action.payload;
    },
    setChosenEventIdx: (state, action) => {
      state.chosenEventIdx = action.payload;
    },
    setEvents: (state, action) => {
      state.events = action.payload;
    },
    setTimezone: (state, action) => {
      state.timezone = action.payload;
    },
    setChosenDateIdx: (state, action) => {
      state.chosenDateIdx = action.payload;
    },
    setDoesDateHaveMore: (state, action) => {
      state.doesDateHaveMore = action.payload;
    },
    setChosenDateEvents: (state, action) => {
      state.chosenDateEvents = action.payload;
    },
    setChosenDate: (state, action) => {
      state.chosenDate = action.payload;
    },
    resetEventSlice: (state) => {
      state.events = initialState.events;
      state.selectedCamera = initialState.selectedCamera;
      state.selectedReviewType = initialState.selectedReviewType;
      state.selectedTag = initialState.selectedTag;
      state.selectedScenarioEvents = initialState.selectedScenarioEvents;
      state.selectedScenarios = initialState.selectedScenarios;
      state.selectedDates = initialState.selectedDates;
      state.chosenEventIdx = initialState.chosenEventIdx;
      state.timezone = initialState.timezone;
      state.chosenDateIdx = initialState.chosenDateIdx;
      state.chosenDateEvents = initialState.chosenDateEvents;
      // state.doesDateHaveMore = initialState.doesDateHaveMore;
      state.chosenDate = initialState.chosenDate;
      state.events = initialState.events;
    }
  }
});

export const {
  setCamera,
  setTag,
  setSelectedDates,
  setSelectedScenarioEvents,
  setSelectedScenarios,
  setChosenEventIdx,
  setEvents,
  setTimezone,
  setChosenDateIdx,
  setDoesDateHaveMore,
  setChosenDateEvents,
  setSelectedReviewType,
  resetEventSlice,
  setChosenDate
} = eventSlice.actions;

export default eventSlice.reducer;
