/* eslint-disable */
import { Button, Spinner } from '@shopify/polaris';

import { Modal, notification } from 'antd';

import { useCallback, useEffect, useState } from 'react';

import SiteForm from './SiteForm';

import { useMutation } from '@tanstack/react-query';

import { createSite } from '../../api/sites.api';

const CreateSiteModal = ({ isOpen, handleCancel = () => {}, setIsOpen, siteCallback }) => {
  const [api, contextHolder] = notification.useNotification();
  const [isEmailValid, setIsEmailValid] = useState(true);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    addressLn1: '',
    addressLn2: '',
    state: '',
    zipcode: '',
    faceBlur: false,
    fullBodyBlur: false,
    stickFigure: false,
    sitemapImage: null,
    webAppNotifications: false,
    webAppNotificationCategories: [],
    announcementNotifications: false,
    announcementNotificationCategories: []
  });

  const onDrop = (files, rejections) => {
    setFormData({ ...formData, sitemapImage: files[0] });
    if (rejections.length) {
      console.warn(rejections);
    }
  };

  useEffect(() => setFormData(formData), [formData]);

  const { mutate, isPending: isCreatingSite } = useMutation({
    mutationFn: createSite,
    onSuccess: () => {
      setIsOpen(false);
      siteCallback.refetch();
      api.success({ message: 'New Site created!' });
    },
    onError: () => {
      api.error({ message: 'Something went wrong. Please try again later.' });
    },
    onSettled: () => {
      setFormData({
        name: '',
        email: '',
        addressLn1: '',
        addressLn2: '',
        state: '',
        zipcode: '',
        faceBlur: false,
        fullBodyBlur: false,
        stickFigure: false,
        webAppNotifications: false,
        webAppNotificationCategories: [],
        announcementNotifications: false,
        announcementNotificationCategories: []
      });
    }
  });

  const handleOk = () => {
    if (
      !formData.addressLn1 ||
      !formData.state ||
      !formData.zipcode ||
      !formData.email ||
      !formData.name ||
      !isEmailValid
    ) {
      return api.warning({
        message: 'Please fill all required fields!'
      });
    }
    mutate({
      ...formData,
      status: 1,
      address_line_1: formData.addressLn1,
      address_line_2: formData.addressLn2,
      face_blur: formData.faceBlur,
      full_body_blur: formData.fullBodyBlur,
      stick_figure: formData.stickFigure,
      web_app_notifications: formData.webAppNotifications,
      web_app_notification_categories: formData.webAppNotificationCategories,
      announcement_notifications: formData.announcementNotifications,
      announcement_notification_categories: formData.announcementNotificationCategories
    });    
  };

  return (
    <>
      {contextHolder}
      <Modal
        title="Create Site"
        open={isOpen}
        onOk={handleOk}
        width={1000}
        onCancel={() => {
          handleCancel();

          setFormData({
            name: '',

            email: '',

            addressLn1: '',

            addressLn2: '',

            state: '',

            zipcode: '',


            faceBlur: false,
            fullBodyBlur: false,
            stickFigure: false,
            webAppNotifications: false,
            webAppNotificationCategories: [],
            announcementNotifications: false,
            announcementNotificationCategories: []
          });
        }}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        maskClosable={false}
        footer={[
          <div className={`inline mr-2`} key="cancel">
            <Button
              variant="secondary"
              onClick={() => {
                handleCancel();

                setFormData({
                  name: '',
                  email: '',
                  addressLn1: '',
                  addressLn2: '',
                  state: '',
                  zipcode: '',
                  faceBlur: false,
                  fullBodyBlur: false,
                  stickFigure: false,
                  webAppNotifications: false,
                  webAppNotificationCategories: [],
                  announcementNotifications: false,
                  announcementNotificationCategories: []
                });
              }}
            >
              Cancel{' '}
            </Button>
          </div>,

          <div className="inline mr-2" key="submit">
            <Button variant="primary" onClick={handleOk} disabled={isCreatingSite}>
              <div className="flex items-center">
                Create
                {isCreatingSite && (
                  <span className="ml-2">
                    <Spinner size="small" />
                  </span>
                )}
              </div>
            </Button>
          </div>
        ]}
      >
        <form>
          <SiteForm
            formData={formData}
            setFormData={setFormData}
            isEmailValid={isEmailValid}
            setIsEmailValid={setIsEmailValid}
            onDrop={onDrop}
          />
        </form>
      </Modal>
    </>
  );
};

export default CreateSiteModal;
