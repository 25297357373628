import React, { useCallback, useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import backArrow from '../../assets/back-arrow.svg';
import {
  Bleed,
  Box,
  Button,
  Card,
  Label,
  Layout,
  LegacyCard,
  Text,
  TextField,
  InlineError,
  Spinner
} from '@shopify/polaris';
import { useMutation } from '@tanstack/react-query';
import {
  // fetchAreaOptionsData,
  // fetchCameraOptionsData,
  // fetchEventsOptionsData,
  fetchAreaCameraScenarioList
} from '../../api/filters.api';
import ScrollableOptions from '../../components/ScrollableOptions';
import { createCameraView } from '../../api/views.api';
import { notification } from 'antd';
import { fetchUniqueEventNames } from '../../api/events.api';

const AddCameraView = () => {
  const [name, setName] = useState('');
  const [selectedCameras, setSelectedCameras] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  // const [areaCameraScenarioList, setAreaCameraScenarioList] = useState([]);
  const [isAreaCameraScenarioListLoading, setIsAreaCameraScenarioListLoading] = useState(false);
  const [isAreaCameraScenarioListError, setIsAreaCameraScenarioListError] = useState(false);
  const [areaListOptions, setAreaListOptions] = useState([]);
  const [cameraListOptions, setCameraListOptions] = useState([]);
  const [masterCameraListOptions, setMasterCameraListOptions] = useState([]);
  const [scenarioEventOptions, setScenarioEventOptions] = useState([]);
  const [isEventLoading, setIsEventLoading] = useState(false);
  const [isEventError, setIsEventError] = useState(false);
  useEffect(() => {

    setIsAreaCameraScenarioListLoading(true);
    fetchAreaCameraScenarioList().then((data) => {
      if (data.success) {
      // setAreaCameraScenarioList(data);
      const areaOptions = data?.data?.arealist?.map((area) => ({
        label: area.area,
        value: area.area
      }));
        setAreaListOptions(areaOptions);

        const cameraOptions = data?.data?.cameraList?.map((camera) => ({
          label: camera.name,
          value: camera.name,
          area: camera.area
        }));
        setCameraListOptions(cameraOptions);
        setMasterCameraListOptions(cameraOptions);
        setIsAreaCameraScenarioListLoading(false);
        setIsAreaCameraScenarioListError(false);
        
      }
      else{
        api.error({
          message: `Error loading areas`,
          placement: 'topRight'
        });
        setIsAreaCameraScenarioListLoading(false);
        setIsAreaCameraScenarioListError(true);
      }
    });
    setIsEventLoading(true);
    fetchUniqueEventNames().then((data) => {
      let uniqueCategories = [];
      data?.data?.map((category) => {
        if (!uniqueCategories.includes(category.scenario_name)) {
          uniqueCategories.push(category.scenario_name);
        }
      });
      let scenarioEventOptions = uniqueCategories.map((category) => ({
        title: category,
        options: data?.data?.filter((item) => item.scenario_name === category).map((item) => ({
          value: item.event_name,
          label: item.event_name
        }))
      }));
      setScenarioEventOptions(scenarioEventOptions);
      setIsEventLoading(false);
      setIsEventError(false);
      
    }).catch((error) => {
      console.log(error,"error");
      setIsEventLoading(false);
      setIsEventError(true);
    });
  }, []);

 

  // Validate form fields
  const validate = useCallback(() => {
    const newErrors = {};
    if (!name.trim()) newErrors.name = 'Name is required.';
    if (!selectedAreas.length) newErrors.areas = 'At least one area must be selected.';
    if (!selectedCameras.length) newErrors.cameras = 'At least one camera must be selected.';
    if (!selectedEvents.length) newErrors.events = 'At least one event must be selected.';

    setErrors(newErrors);

    // Return true if there are no errors, else false
    return Object.keys(newErrors).length === 0;
  }, [name, selectedAreas, selectedCameras, selectedEvents]);

  // Mutation for submitting the form
  const { mutate } = useMutation({
    mutationFn: createCameraView,
    onSuccess: () => {
      api.success({
        message: 'CameraView Created successfully.',
        placement: 'topRight'
      });
      setTimeout(() => {
        navigate('/views');
      }, 1000);
    },
    onError: () => {
      api.error({
        message: `Error creating Camera View`,
        placement: 'topRight'
      });
      setIsSubmitting(false);
    }
  });

  const handleInputChange = (setter, field) => (value) => {
    if (value.length > 100) return;
    setter(value);

    // Clear the error for the specific field as soon as the user types or selects
    /* eslint-disable no-unused-vars */
    setErrors((prevErrors) => {
      const { [field]: _, ...remainingErrors } = prevErrors;
      return remainingErrors;
    });

    if (field === 'areas') {
      let selectedCameras = [];
      if (value.length > 0) {
      masterCameraListOptions.forEach((camera) => {
        console.log(camera,"camera");
        if (value.includes(camera.area)) {
          selectedCameras.push({
            label: camera.value,
            value: camera.value,
            area: camera.area
          });
        }
      });
      console.log(selectedCameras,"selectedCameras");
      setCameraListOptions(selectedCameras);
      }
      else{
        setCameraListOptions(masterCameraListOptions);
      }
      
      // refetchCameras();
    }
  };

  const handleSubmit = async () => {
    if (!validate()) return;

    setIsSubmitting(true);
    const payload = {
      name,
      camera_names: selectedCameras.join(','),
      area_names: selectedAreas.join(','),
      event_names: selectedEvents.join(',')
    };

    mutate(payload);
  };

  return (
    <>
      <div className="radiousnone main-title">
        <Card roundedAbove="xs">
          <div className="title flex items-center justify-between smallbtn">
            <div className="flex items-center gap-3">
              <button onClick={() => navigate('/views')}>
                <img src={backArrow} alt="Back" />
              </button>
              <div className="title">
                <h5 className="text-lg font-semibold">Add View</h5>
              </div>
            </div>
          </div>
        </Card>
      </div>

      <div className="px-4 py-4">
        {contextHolder}
        <Card>
          <Bleed marginInline="400" marginBlockStart="400">
            <Box background="bg-surface-secondary" padding="400">
              <Text as="h3" variant="headingMd" fontWeight="semibold">
                Add View
              </Text>
            </Box>
          </Bleed>
          <div className="max-w-4xl mx-auto">
            <div className="basicdetail height-43 space-y-5 pt-5 pb-5">
              <div className="block sm:flex items-center gap-5 w-full">
                <div className="w-[200px] pb-1">
                  <Label>
                    Name <span className="text-red-500">*</span>{' '}
                  </Label>
                </div>
                <div className="w-full relative">
                  <TextField
                    type="text"
                    labelHidden
                    placeholder="Office View 1"
                    value={name}
                    onChange={handleInputChange(setName, 'name')}
                    autoComplete="off"
                  />
                  {errors.name && <InlineError message={errors.name} fieldID="name" />}
                </div>
              </div>

              <div className="grid gridcols-1 md:grid-cols-1 lg:grid-cols-3 2xl:grid-cols-3 gap-4">
                <Layout>
                  <Layout.Section>
                    <LegacyCard>
                      <div className="flex items-center justify-between flex-wrap w-full gap-5 py-2.5 px-4">
                        <h3 className="font-semibold text-base">
                          Areas <span className="text-red-500">*</span>{' '}
                        </h3>
                      </div>
                      <hr />
                      <ScrollableOptions
                        options={areaListOptions}
                        selected={selectedAreas}
                        setSelected={handleInputChange(setSelectedAreas, 'areas')}
                        isLoading={isAreaCameraScenarioListLoading}
                        isError={isAreaCameraScenarioListError}
                        errorMessage="Error loading areas"
                        title={null}
                        hasHeading={false}
                      />
                      {errors.areas && <InlineError message={errors.areas} fieldID="areas" />}
                    </LegacyCard>
                  </Layout.Section>
                </Layout>

                <Layout>
                  <Layout.Section>
                    <LegacyCard>
                      <div className="flex items-center justify-between flex-wrap w-full gap-5 py-2.5 px-4">
                        <h3 className="font-semibold text-base">
                          Cameras <span className="text-red-500">*</span>{' '}
                        </h3>
                      </div>
                      <hr />
                      <ScrollableOptions
                        options={cameraListOptions}
                        selected={selectedCameras}
                        setSelected={handleInputChange(setSelectedCameras, 'cameras')}
                        isLoading={isAreaCameraScenarioListLoading}
                        isError={isAreaCameraScenarioListError}
                        errorMessage="Error loading cameras"
                        title={null}
                        hasHeading={false}
                      />
                      {errors.cameras && <InlineError message={errors.cameras} fieldID="cameras" />}
                    </LegacyCard>
                  </Layout.Section>
                </Layout>

                <Layout>
                  <Layout.Section>
                    <LegacyCard>
                      <div className="flex items-center justify-between flex-wrap w-full gap-5 py-2.5 px-4">
                        <h3 className="font-semibold text-base">
                          Scenarios <span className="text-red-500">*</span>{' '}
                        </h3>
                      </div>
                      <hr />
                      <ScrollableOptions
                        options={scenarioEventOptions}
                        selected={selectedEvents}
                        setSelected={handleInputChange(setSelectedEvents, 'events')}
                        isLoading={isEventLoading}
                        isError={isEventError}
                        errorMessage="Error loading events"
                        title={null}
                        hasHeading={true}
                      />
                      {errors.events && <InlineError message={errors.events} fieldID="events" />}
                    </LegacyCard>
                  </Layout.Section>
                </Layout>
              </div>
            </div>
            <div className="w-full flex justify-end gap-4 mb-5">
              <Button
                onClick={() => {
                  navigate('/views');
                }}
                variant="secondary">
                Cancel
              </Button>
              <Button onClick={handleSubmit} variant="primary" disabled={isSubmitting}>
                <div className="flex items-center">
                  Submit
                  {isSubmitting && (
                    <span className="ml-2">
                      <Spinner size="small" />
                    </span>
                  )}
                </div>
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddCameraView;
