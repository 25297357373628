/* eslint-disable */
import { Button, Divider, Icon } from '@shopify/polaris';
import EventCard from './EventCard';
import Spacer from '../../components/Spacer';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetEventSlice,
  setChosenDate,
  setChosenDateEvents,
  setChosenDateIdx
} from '../../store/slices/eventSlice';
import { useMutation } from '@tanstack/react-query';
import { fetchEventsByDate } from '../../api/events.api';
import { formatDate } from '../../utils';
import { notification } from 'antd';
import { ChevronLeftMinor } from '@shopify/polaris-icons';
import { useEffect, useState } from 'react';
import { Pagination, Spinner } from '@shopify/polaris';


const EventsPerDate = ({ events, date, currentIdx, data: eventData }) => {
  const [api, contextHolder] = notification.useNotification();

  const dispatch = useDispatch();
  const {
    selectedCamera,
    selectedTag,
    selectedDates,
    selectedScenarioEvents,
    chosenDateIdx,
    doesDateHaveMore,
    chosenDateEvents,
    selectedReviewType,
    chosenDate,
    page,
  } = useSelector((state) => state.event);

  const [pages, setPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);


  const onViewMore = () => {
    if (chosenDateIdx !== null) {
      dispatch(setChosenDateIdx(null));
      dispatch(setChosenDate(null));
      dispatch(setChosenDateEvents([]));
      dispatch(resetEventSlice());
      
    } else {
      dispatch(setChosenDateIdx(currentIdx));
      dispatch(setChosenDate(date));
      mutate();
    }
  };

  const { mutate } = useMutation({
    mutationFn: () =>
      fetchEventsByDate({
        eventNames: selectedScenarioEvents,
        camera: selectedCamera,
        tag: selectedTag,
        startDate: formatDate(selectedDates.start),
        endDate: formatDate(selectedDates.end),
        specificDate: chosenDate,
        reviewType: selectedReviewType,
        page: currentPage
      }),
    onSuccess: (data) => {
      setPages(data.pages);
      setCurrentPage(data.page);
      console.log(data, "hey sumanth")
      if (Object.keys(data.data).length === 0) {
        dispatch(setChosenDateEvents([]));
      } else dispatch(setChosenDateEvents(data.data));
      // return api.success({ message: 'Events fetched successfully. Please scroll down!' });
    },
    onError: () => {
      dispatch(setChosenDateIdx(null));
      dispatch(setChosenDateEvents([]));
      return api.error({ message: 'Unable to fetch events. Please try again later!' });
    }
  });

  useEffect(() => {
    if (chosenDateIdx) {
      mutate();
    }
  }, [
    mutate,
    selectedCamera,
    selectedTag,
    selectedScenarioEvents,
    selectedDates,
    selectedReviewType,
    chosenDate
  ]);

  return (
    <div
      // className={`my-4 bg-white rounded-md p-4 shadow-md ${chosenDateIdx !== null && currentIdx !== chosenDateIdx && 'hide'}`}
      className={`my-4 bg-white rounded-md p-4 shadow-md ${chosenDateIdx !== null && chosenDate !== date && 'hide'}`}
    >
      {contextHolder}
      <div className="flex items-center justify-between">
        <div className="flex">
          {chosenDateIdx !== null && (
            <span className="mr-4">
              <Button onClick={onViewMore} variant="plain">
                <div className="flex items-center">
                  <Icon source={ChevronLeftMinor} />
                  <span className="ml-1">Go Back to All Events</span>
                </div>
              </Button>
            </span>
          )}
          <h3 className="font-semibold">{date}</h3>
        </div>

        {doesDateHaveMore && doesDateHaveMore[date] && chosenDateIdx === null && (
          <Button onClick={onViewMore} variant="secondary">
            View More
          </Button>
        )}
      </div>
      <Spacer height={'10px'} />
      <Divider />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5 pt-4">
        {chosenDate !== null
          ? chosenDateEvents?.map((event, idx) => {
              return <EventCard key={idx} event={event} />;
            })
          : events.map((event, idx) => {
              return <EventCard key={idx} event={event} />;
            })}

          
      </div>
{chosenDateIdx !== null && (
      <div className="flex justify-center bg-white rounded-md shadow-xl py-3">
                      <Pagination
                      onPrevious={() => {
                        
                        setCurrentPage((prev) => prev - 1);
                        mutate();
                      }}
                      onNext={() => {
                        setCurrentPage((prev) => prev + 1);
                        mutate();
                      }}
                      hasNext={currentPage < pages}
                      hasPrevious={currentPage > 1}
                      label={`Page ${currentPage} of ${pages}`}

          />
        </div>
      )}
    </div>
  );
};

export default EventsPerDate;
