import { useCallback, useState } from 'react';
import backgroundImage from '../../assets/login-bg.png';
import VisionifyLogo from '../../assets/visionify-logo.svg';
import { Button, Spinner, TextField } from '@shopify/polaris';
import { notification } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { forgotPassword, login } from '../../api/auth.api';
import { getEmailRegex } from '../../utils';
import { useDispatch } from 'react-redux';
import { setAuthObject } from '../../store/slices/authSlice';
import { Eye, EyeOff } from 'lucide-react';
import { setAvatarGradient } from '../../store/slices/appSlice';

const availableAvatarBgs = [
  `from-[#E3F0DF] to-[#CBE9C0]`,
  `from-[#E5ECF9] to-[#C4D4F3]`,
  `from-[#FAE5E3] to-[#F8CAC7]`,
  `from-[#EBE4FF] to-[#C7BAEC]`
];

const Login = () => {
  const [api, contextHolder] = notification.useNotification();
  const [form, setForm] = useState({
    email: '',
    password: ''
  });

  const [showPassword, setShowPassword] = useState(false);

  const [showLogin, setShowLogin] = useState(true);
  const [showPasswordResetMessage, setShowPasswordResetMessage] = useState(false);

  const dispatch = useDispatch();

  const { mutate: onLogin } = useMutation({
    mutationFn: login,
    onSuccess: (response) => {
      dispatch(
        setAuthObject({
          user: response.user,
          site: {
            id: response.site?.id || response.user?.site_id || 1,
            name: response.site?.name || '',
            enable_web_app_notifications: response.site?.enable_web_app_notifications || false
          }
        })
      );
      const randomInt = (max) => Math.floor(Math.random() * max);
      dispatch(setAvatarGradient(availableAvatarBgs[randomInt(availableAvatarBgs.length)]));
    },
    onError: () => {
      api.error({ message: 'Username and/or Password is incorrect.' });
    }
  });

  const { mutate: onForgotPassword, isPending: isLoggingIn } = useMutation({
    mutationFn: forgotPassword,
    onSuccess: () => {
      api.info({ message: 'Check your email to reset your password.' });
      setShowPasswordResetMessage(true);
    },
    onError: (error) => {
      const errorMessage = error.message || 'An unexpected error occurred.';
      api.error({ message: errorMessage });
    }
  });

  const handleLogin = useCallback(
    (e) => {
      e.preventDefault();
      const emailRegex = getEmailRegex();
      if (!emailRegex.test(form.email)) {
        return api.error({ message: 'Please enter a valid email' });
      }
      if (form.password === '') {
        return api.error({ message: 'Please enter your password' });
      }
      onLogin(form);
    },
    [form]
  );

  const handleForgotPassword = useCallback(
    (e) => {
      e.preventDefault();
      const emailRegex = getEmailRegex();
      if (!emailRegex.test(form.email)) {
        return api.error({ message: 'Please enter a valid email' });
      }

      onForgotPassword({ email: form.email });
    },
    [form]
  );

  return (
    <div
      className="bg-center bg-cover h-screen w-screen relative"
      style={{
        backgroundImage: `url(${backgroundImage})`
      }}>
      {contextHolder}
      <style>
        {`
            .Polaris-TextField__Input {
                padding: 12px;
            }
            .Polaris-Button {
                height: 40px;
            }
            `}
      </style>
      <div className="flex justify-evenly items-center h-full">
        <div className="hide lg:block">
          <h1 className="text-7xl font-extrabold leading-extra-loose">
            NextGen
            <br />
            Workplace Safety <br />
            with Vision AI
          </h1>
          <p className="mb-4 text-xl text-wrap leading-loose">
            Elevate Workplace Safety and Compliance with our advanced Vision AI Apps <br /> and
            experience continuous monitoring, instant alerts and video analytics <br /> tailored to
            your safety needs.
          </p>
          <div className="mb-4">
            <a
              className="text-[#8670C5] mb-4 text-xl hover:underline"
              target="_blank"
              href="https://visionify.ai"
              rel="noreferrer">
              Visionify Inc.
            </a>
          </div>
          <span className="text-sm">Copyright © 2024 Visionify Inc.</span>
        </div>
        <div>
          <div className="bg-white border shadow-lg px-6 py-8 rounded-xl h-[596px] w-[498px] flex flex-col justify-center">
            <div className="w-full flex justify-center">
              <img className="mb-4" src={VisionifyLogo} alt="Visionify Logo" />
            </div>
            {showLogin ? (
              <>
                <div className="font-semibold text-lg w-full text-center">Sign in</div>
                <form className="mt-10" onSubmit={handleLogin}>
                  <div className="mb-4">
                    <TextField
                      value={form.email}
                      onChange={(newValue) => setForm({ ...form, email: newValue })}
                      placeholder="admin@visionify.ai"
                      label="Email"
                      type="email"
                    />
                  </div>
                  <div className="mb-4">
                    <TextField
                      suffix={
                        <span
                          className="cursor-pointer"
                          onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? <EyeOff /> : <Eye />}
                        </span>
                      }
                      value={form.password}
                      onChange={(newValue) => setForm({ ...form, password: newValue })}
                      placeholder="**********"
                      type={showPassword ? 'text' : 'password'}
                      label="Password"
                    />
                  </div>
                  <div className="flex justify-end mb-4">
                    <Button variant="plain" onClick={() => setShowLogin(false)}>
                      Forgot your password?
                    </Button>
                  </div>

                  <Button fullWidth variant="primary" submit>
                    <div className="flex items-center">
                      <span className="mr-2">Sign In</span>
                      {isLoggingIn && (
                        <span>
                          <Spinner size="small" />
                        </span>
                      )}
                    </div>
                  </Button>
                </form>
              </>
            ) : (
              <>
                <div className="font-semibold text-lg w-full text-center">
                  Forgot your password?
                </div>
                {!showPasswordResetMessage ? (
                  <form className="mt-10" onSubmit={handleForgotPassword}>
                    <div className="mb-12">
                      <TextField
                        value={form.email}
                        onChange={(newValue) => setForm({ ...form, email: newValue })}
                        placeholder="admin@visionify.ai"
                        label="Email"
                        type="email"
                      />
                    </div>
                    <Button fullWidth variant="primary" submit disabled={isLoggingIn}>
                      <div className="flex items-center">
                        <span className="mr-2">Request Reset Link</span>
                        {isLoggingIn && (
                          <span>
                            <Spinner size="small" />
                          </span>
                        )}
                      </div>
                    </Button>
                  </form>
                ) : (
                  <div className="flex justify-center">
                    <p className="text-center mt-4">
                      If{' '}
                      <a
                        className="text-blue-500 hover:text-blue-800 underline"
                        href={`mailto:${form.email}`}>
                        {form.email}
                      </a>{' '}
                      exists, you will receive an email there shortly. You may need to check your
                      spam folder.
                    </p>
                  </div>
                )}
                <div className="flex justify-center my-4">
                  <Button
                    variant="plain"
                    onClick={() => {
                      setShowLogin(true);
                      setForm({ email: '', password: '' });
                      setShowPasswordResetMessage(false);
                    }}>
                    Back to Login
                  </Button>
                </div>
              </>
            )}
          </div>
          <div className="flex justify-around mt-5">
            <a
              className="hover:underline"
              href="https://visionify.ai/privacy-policy/"
              target="_blank"
              rel="noreferrer">
              Privacy Policy
            </a>
            <a
              className="hover:underline"
              href="https://visionify.ai/terms-and-conditions/"
              target="_blank"
              rel="noreferrer">
              Terms of Use
            </a>
          </div>
        </div>
      </div>
      <div className="h-[18px] bg-[#8670C5] absolute bottom-0 w-full"></div>
    </div>
  );
};

export default Login;
