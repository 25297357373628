import { emailApi } from './index';
import { api } from './index';

export const fetchAreaCameraScenarioList = async () => {
  try {
    const response = await api.get(`/camera-views/filters/area-camera-list`);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const fetchEventsOptionsData = async () => {
  try {
    const response = await emailApi.get(`/scenario-events`);
    const events = response.data.map((category) => ({
      title: category.title,
      options: category.options.map((option) => ({
        value: option.value,
        label: option.label
      }))
    }));
    return events;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const fetchAreaOptionsData = async () => {
  try {
    const response = await emailApi.get(`/area-list`);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const fetchCameraOptionsData = async (areas = null) => {
  try {
    const queryParams = areas ? `?areas=${areas.join(',')}` : '';
    const response = await emailApi.get(`/camera-list${queryParams}`);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
