import { Icon, LegacyCard, Tabs, Tooltip } from '@shopify/polaris';

import { useCallback, useState } from 'react';

import CompanyInfo from './CompanyInfo';
import SiteInfo from './SiteInfo';
import { PlusMinor, QuestionMarkMajor } from '@shopify/polaris-icons';
import CreateSiteModal from './CreateSiteModal';
import EditSiteModal from './EditSiteModal';

import { useQuery } from '@tanstack/react-query';
import { getSites } from '../../api/sites.api';


import MsTeamsWebhookInfo from './MsTeamsWebhookInfo';
import MsTeamsWebhookModal from './MsTeamsWebhookModal';
import IntegrationInfo from './Integrations/IntegrationInfo';
import IntegrationModal from './Integrations/IntegrationModal';
import { getMSTeamsWebhooks } from '../../api/msteamswebhooks.api';
import { getIntegrations } from '../../api/integrations.api';
import { useSelector } from 'react-redux';

const renderTabContent = (
  selectedId,
  { setShowSiteEditModal, siteCallback, setChosenSiteData },
  { setShowTeamsWebhookModal, msTeamsWebhookCallback, setChosenTeamsWebhookData },
  { setShowIntegrationModal, integrationCallback, setChosenIntegrationData }
) => {
  switch (selectedId) {
    case 'company-info':
      return <CompanyInfo />;

    case 'site-info':
      return (
        <SiteInfo
          setShowSiteEditModal={setShowSiteEditModal}
          siteCallback={siteCallback}
          setChosenSiteData={setChosenSiteData}
        />
      );

    case 'ms-teams-info':
      return (
        <MsTeamsWebhookInfo
          setShowTeamsWebhookModal={setShowTeamsWebhookModal}
          msTeamsWebhookCallback={msTeamsWebhookCallback}
          setChosenTeamsWebhookData={setChosenTeamsWebhookData}
        />
      );
    case 'integrations':
      return (
        <IntegrationInfo
          setShowIntegrationModal={setShowIntegrationModal}
          integrationCallback={integrationCallback}
          setChosenIntegrationData={setChosenIntegrationData}
        />
      );
    default:
      return null;
  }
};

const CompanySettings = () => {
  const { user } = useSelector((state) => state.auth);
  const [selected, setSelected] = useState(0);
  const [showSiteCreationModal, setShowSiteCreationModal] = useState(false);
  const [showSiteEditModal, setShowSiteEditModal] = useState(false);
  const [chosenSiteData, setChosenSiteData] = useState(null);
  const [showTeamsWebhookModal, setShowTeamsWebhookModal] = useState(false);
  const [chosenTeamsWebhookData, setChosenTeamsWebhookData] = useState(null);
  const [showIntegrationModal, setShowIntegrationModal] = useState(false);
  const [chosenIntegrationData, setChosenIntegrationData] = useState(null);

  const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);

  const siteCallback = useQuery({
    queryKey: ['siteInfo'],

    queryFn: getSites
  });

  const msTeamsWebhookCallback = useQuery({
    queryKey: ['msteamswebhooks'],
    queryFn: getMSTeamsWebhooks
  });

  const integrationCallback = useQuery({
    queryKey: ['integrations'],
    queryFn: getIntegrations
  });

  const tabs = [
    {
      id: 'company-info',
      content: 'Company',
      accessibilityLabel: 'Company Info',
      panelID: 'company-info'
    },
    ...(user.role_id !== 4
      ? [
          {
            id: 'site-info',
            content: 'Sites',
            accessibilityLabel: 'Sites Info',
            panelID: 'site-info'
          }
        ]
      : []),
    {
      id: 'ms-teams-info',
      content: 'Microsoft Teams',
      accessibilityLabel: 'Microsoft Teams',
      panelID: 'ms-teams-info'
    },
    {
      id: 'integrations',
      content: 'Integrations',
      accessibilityLabel: 'Integrations',
      panelID: 'integrations-info'
    }
  ];

  const renderActions = (selected) => {
    switch (selected) {
      case 'site-info':
        if (user && (user.role_id === 1 || user.role_id === 2 || user.role_id === 3)) {
          return [
            {
              content: 'Add New Site',
              icon: <Icon source={PlusMinor} />,
              onAction: () => setShowSiteCreationModal(true)
            }
          ];
        }
        return <></>;
      case 'ms-teams-info':
        if (user && (user.role_id === 1 || user.role_id === 2 || user.role_id === 3)) {
          return [
            {
              content: 'Add New Webhook',
              icon: <Icon source={PlusMinor} />,
              onAction: () => {
                setShowTeamsWebhookModal(true);
                setChosenTeamsWebhookData(null);
              }
            },
            {
              content: (
                <Tooltip content="Create Teams webhook">
                  <div
                    onClick={() =>
                      window.open(
                        'https://learn.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook?tabs=newteams%2Cdotnet#create-an-incoming-webhook',
                        '_blank'
                      )
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    <Icon source={QuestionMarkMajor} color="inkLighter" />
                  </div>
                </Tooltip>
              )
            }
          ];
        }
        return <></>;
      case 'integrations':
        if (user && (user.role_id === 1 || user.role_id === 2 || user.role_id === 3)) {
          return [
            {
              content: 'Add New Integration',
              icon: <Icon source={PlusMinor} />,
              onAction: () => setShowIntegrationModal(true)
            }
          ];
        }
        return <></>;
      default:
        return [];
    }
  };

  return (
    <div className="flex flex-col mx-4 my-4">
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
        <LegacyCard.Section
          title={tabs[selected].content}
          actions={renderActions(tabs[selected].id)}
        >
          {renderTabContent(
            tabs[selected].id,
            { setShowSiteEditModal, siteCallback, setChosenSiteData },
            { setShowTeamsWebhookModal, msTeamsWebhookCallback, setChosenTeamsWebhookData },
            { setShowIntegrationModal, integrationCallback, setChosenIntegrationData }
          )}
        </LegacyCard.Section>
      </Tabs>

      <CreateSiteModal
        isOpen={showSiteCreationModal}
        setIsOpen={setShowSiteCreationModal}
        handleCancel={() => setShowSiteCreationModal(false)}
        siteCallback={siteCallback}
      />

      <EditSiteModal
        isOpen={showSiteEditModal}
        chosenSiteData={chosenSiteData}
        setIsOpen={setShowSiteEditModal}
        siteCallback={siteCallback}
        handleCancel={() => {
          setShowSiteEditModal(false);
          setChosenSiteData(null);
        }}
      />

      <MsTeamsWebhookModal
        isOpen={showTeamsWebhookModal}
        chosenTeamsWebhookData={chosenTeamsWebhookData}
        setChosenTeamsWebhookData={setChosenTeamsWebhookData}
        setIsOpen={setShowTeamsWebhookModal}
        msTeamsWebhookCallback={msTeamsWebhookCallback}
        handleCancel={() => {
          setShowTeamsWebhookModal(false);
          setChosenTeamsWebhookData(null);
        }}
      />
      <IntegrationModal
        isOpen={showIntegrationModal}
        chosenIntegrationData={chosenIntegrationData}
        setChosenIntegrationData={setChosenIntegrationData}
        setIsOpen={setShowIntegrationModal}
        integrationCallback={integrationCallback}
        handleCancel={() => {
          setShowIntegrationModal(false);
          setChosenIntegrationData(null);
        }}
      />
    </div>
  );
};

export default CompanySettings;
