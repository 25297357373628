import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from '@shopify/polaris';
import { NotificationMajor, NotificationFilledMajor } from '@shopify/polaris-icons';
import { addNotification, markAsRead, clearNotifications } from '../store/slices/notificationSlice';
import { socketConnection } from '../sockets';
import { NotificationMessage } from './NotificationMessage';
import voilation_audio from '../assets/voilation.mp3';
import { store } from '../store/store';
import { v4 as uuidv4 } from 'uuid';
import NotificationList from './NotificationList';


const NotificationIcon = () => {

  const { auth } = store.getState();

  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications?.notifications || []);
  // const unreadCount = useSelector((state) => state.notifications?.unreadCount || 0);
  // const [unreadEvents, setUnreadEvents] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationData, setNotificationData] = useState({});
  const [notificationKey, setNotificationKey] = useState(0); // Add key counter
  const [isModalOpen, setIsModalOpen] = useState(false);


  useEffect(() => {
    const socket = socketConnection();
    const playAudio = async (eventData) => {
      try {


        // Cancel any ongoing speech
        window.speechSynthesis.cancel();
        let text = ''
        const formattedEventName = eventData?.event_names?.replace?.('phone', 'Mobile Phone Usage') || eventData?.event_names;

        text = `${formattedEventName} voilation detected on ${eventData.camera_name}`;

        // Create utterance
        const utterance = new SpeechSynthesisUtterance(text);
        utterance.rate = 1.0;
        utterance.pitch = 1.0;
        utterance.volume = 1.0;

        // Add event listeners for debugging
        utterance.onstart = () => console.log('Started speaking');
        utterance.onend = () => console.log('Finished speaking');
        utterance.onerror = (e) => console.error('Speech error:', e);

        // Wait for voices to load
        if (speechSynthesis.getVoices().length === 0) {
          await new Promise(resolve => {
            speechSynthesis.onvoiceschanged = resolve;
          });
        }

        // Get available voices
        const voices = speechSynthesis.getVoices();

        // Define preferred voices
        const PREFERRED_VOICES = [
          'Google UK English Female',
          // 'Microsoft Zira Desktop',
          // 'Google US English',
          // 'en-US',
          // 'en-GB'
        ];

        // Try to find preferred voice
        const preferredVoice = voices.find(voice =>
          PREFERRED_VOICES.some(preferred =>
            voice.name.includes(preferred) || voice.lang.includes(preferred)
          )
        );

        // Set voice with fallback
        utterance.voice = preferredVoice || voices[0];
        console.log('Selected voice:', utterance.voice?.name);

        // Speak
        window.speechSynthesis.speak(utterance);


      } catch (error) {
        console.error('Speech error:', error);
        const audio = new Audio(voilation_audio);
        audio.play().catch(error => {
          console.error("Error playing the audio:", error);
        });
      }
    };
    const handleNotification = (data) => {

      try {

        console.log(data,"data");
        if (!auth.chosenSite.enable_web_app_notifications) {
          return;
        }

        if (data.site_id !== auth.chosenSite?.id) {
          return;
        }
        data.read = false;
        data.id = data.id || uuidv4();
        data.timestamp = data.timestamp || new Date().toISOString();
        if (data.sound) {
          dispatch(addNotification(data));
          setNotificationData({
            text: `${data.camera_name} voilation Detected`,
            position: 'bottomRight',
            color: 'critical',
            data: data
          });
          setShowNotification(true);

          playAudio(data);
        } else if (data.pop_up) {
          dispatch(addNotification(data));
          setNotificationData({
            text: `${data.camera_name} voilation Detected`,
            position: 'bottomRight',
            color: 'critical',
            data: data
          });
          setShowNotification(true);

        }

        setNotificationKey(prev => prev + 1); // Increment key



      } catch (error) {
        console.error('Error processing notification:', error);
      }
    };


    socket.on('web_app_notifications', handleNotification);

    return () => {
      socket.off('web_app_notifications', handleNotification);
    };
  }, [dispatch]);


  const markNotificationAsRead = (data) => {
    dispatch(markAsRead(data));
  }

  const dismissAll = () => {
    dispatch(clearNotifications());
  }



  return (
    <div>

      <div style={{ position: 'relative', cursor: 'pointer' }}>
        {showNotification && (
          <NotificationMessage
            {...notificationData}
            key={notificationKey}
            markNotificationAsRead={markNotificationAsRead}
          />
        )}
        {notifications.length > 0 ? (
          <div style={{ position: 'relative' }}
            onClick={() => setIsModalOpen(true)}
          >

            <Icon
              source={NotificationFilledMajor}
              tone="base"
            />
            {notifications.length > 0 && (
              <div style={{
                position: 'absolute',
                top: '-8px',
                right: '-8px',
                backgroundColor: '#de3618',
                color: 'white',
                borderRadius: '50%',
                minWidth: '16px',
                height: '16px',
                fontSize: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '2px'
              }}>
                {notifications.length}

              </div>

            )}
          </div>


        ) : (
          <Icon
            source={NotificationMajor}
            tone="base"
          />
        )}
      </div>
      <NotificationList
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        markNotificationAsRead={markNotificationAsRead}
        dismissAll={dismissAll}
      />
    </div>
  );
};

export default NotificationIcon;